import { Observable } from 'rxjs';
import { CartItemModel } from './cart-item-model';
import { CartModel } from './cart-model';
import { CartSubmitModel } from './cart-submit.model';

export abstract class CartPersistenceService {
    public abstract loadCart(): Observable<CartModel>;
    public abstract addToCart(item: CartItemModel, isDelivery: boolean): Observable<CartModel>;
    public abstract changeItem(item: CartItemModel, isDelivery: boolean): Observable<CartModel>;
    public abstract changeItemDiscount(posNr: number, discount: number): Observable<CartModel>;
    public abstract removeItem(item: CartItemModel): Observable<CartModel>;
    public abstract book(request: CartSubmitModel): Observable<void>;
}
