/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for OxApi services
 */
@Injectable({
  providedIn: 'root',
})
export class OxApiConfiguration {
  rootUrl: string = '//api.naue.com';
}

export interface OxApiConfigurationInterface {
  rootUrl?: string;
}
