import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CoaSearchOptions } from '../shared/coa/coa-search-options';
import { CoaModel } from '../shared/coa/coa.model';
import { CoaService } from '../shared/coa/coa.service';
import { NaueGLB } from './naue/models';
import { NaueConfirmationOfArrivalEndpointService } from './naue/services';
import { TableAdapter } from './table-adapter';

@Injectable()
export class OxCoaService extends CoaService {
    constructor(private coaService: NaueConfirmationOfArrivalEndpointService) {
        super();
    }

    getCountOpen(): Observable<number> {
        return this.coaService.countOpenGLBUsingGET().pipe(
            catchError(err => {
                console.error(err);
                return of(0);
            })
        );
    }

    getList(searchOptions: CoaSearchOptions): Observable<CoaModel[]> {
        // const isReceived = searchOptions.openOnly ? false : undefined;
        // Strange backend implementation: isReceived=true for all, isReceived=false für only open
        return this.coaService.listGLBUsingGET(!searchOptions.openOnly).pipe(
            map(resp => new TableAdapter<OxCoaItem>().convertTableToArray(resp)),
            map(items => items.map(item => this.transformCoaItemModel(item)))
        );
    }

    saveAll(items: CoaModel[]): Observable<void> {
        const itemsToSave = items.map(item => this.transformModelToCoaItem(item));
        return this.coaService.saveGLBUsingPOST(itemsToSave).pipe(map(() => null));
    }

    private transformCoaItemModel(coa: OxCoaItem): CoaModel {
        const deliveryDate = new Date(coa.RKYBUD);

        return {
            city: coa.AAORT,
            confirmed: coa.LKGLBT === '2',
            confirmedDate: new Date(coa.LKYGBE).getTime(),
            confirmedUserName: coa.KEKNAM,
            country: coa.FRFRSB,
            deliveryMonth: deliveryDate.getTime() < 0 ? 0 : deliveryDate.getMonth() + 1,
            deliveryYear: deliveryDate.getTime() < 0 ? 0 : deliveryDate.getFullYear(),
            deliveryNoteNbr: coa.LKLFNR,
            invoiceNbr: coa.RKRNNR,
            saved: coa.LKGLBT === '2',
            nbr: coa.LKLFNR,
            remarks: coa.LKGLTX,
            zipCode: coa.AAPLZI,
        };
    }

    private transformModelToCoaItem(coa: CoaModel): NaueGLB {
        return { checked: coa.confirmed, lfnr: coa.nbr.toString(), note: coa.remarks };
    }
}

interface OxCoaItem {
    RKFIRM: string;
    RKRNNR: string;
    RKAUNR: string;
    KOSPKZ: string;
    LKLAG1: string;
    PKADR1: string;
    PKADR2: string;
    PKADR3: string;
    PKADR4: string;
    PKPLZI: string;
    LALISO: string;
    PKORT: string;
    LALNAM: string;
    PKPKNR: string;
    AAPLZI: string;
    AAORT: string;
    FRFRSB: string;
    LKGLBT: string;
    LKYGBE: string;
    LKSAGE: string;
    LKGLTX: string;
    RKYBUD: string;
    LKLFNR: string;
    KEKNAM: string;
}
