import { Injectable } from '@angular/core';
import { CimLoggingService } from '@cimdata/cim-logging';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { OpenPositionModel } from '../shared/openpos/open-position-model';
import { OpenPositionService } from '../shared/openpos/open-position-service';
import { GetPersonalAccountsOpenItemsService } from './naue/services';
import { TableAdapter } from './table-adapter';

@Injectable()
export class OxOpenPositionService extends OpenPositionService {
  constructor(
    private openPosService: GetPersonalAccountsOpenItemsService,
    private logger: CimLoggingService
  ) {
    super();
   }

  public loadList(): Observable<OpenPositionModel[]> {
    return this.openPosService.getPersonalAccountsOpenItemsUsingPOST().pipe(
      map(list => list ? new TableAdapter<OxOpenPosition>().convertTableToArray(list.table).map(o => this.mapOpenPos(o)) : []),
      catchError(err => {
        this.logger.error(err);
        return of([]);
      })
    );
  }

  public getCount(): Observable<number> {
    return this.openPosService.getPersonalAccountsOpenItemsUsingPOST().pipe(
      map(list => list?.table?.rowCount || 0)
    );
  }

  private mapOpenPos(pos: OxOpenPosition): OpenPositionModel {
    return {
      currencyCode: pos.PEWESL,
      dueDate: new Date(pos.PEYFLG).getTime(),
      invoice: pos.BLNR,
      invoiceDate: new Date(pos.PEYBLG).getTime(),
      invoiceDocUrl: pos.DOKID,
      order: pos.PEFAUG,
      warningLevel: parseInt(pos.PEMSTF, 10),
      constructionProject: pos.KOKBBZ,
      total: parseFloat(pos.PEUBTR.replace(',', '.')) || parseFloat(pos.PEBETR.replace(',', '.')),
      due: parseFloat(pos.PEBETR.replace(',', '.')),
    };
  }
}

interface OxOpenPosition {
  PEFAUG: string;
  BLNR: string;
  PEYFLG: string;
  PEMSTF: string;
  DOKID: string;
  PEYBLG: string;
  KOKBBZ: string;
  PEWESL: string;
  PEBETR: string;
  PEUBTR: string;
}
