import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginService } from './auth/login-service';
import { UserSession } from './user-session';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    protected sessionSubject$ = new BehaviorSubject<UserSession>(null);

    public session$ = this.sessionSubject$.pipe();
    public isAuthenticated$ = this.sessionSubject$.pipe(map(x => !!x));

    constructor(private router: Router, private loginService: LoginService) {
        this.restoreSession();
    }

    public login(username: string, password: string): Observable<boolean> {
        return this.loginService.login(username, password).pipe(
            map(loginResult => {
                if (loginResult) {
                    const session: UserSession = {
                        expires: loginResult.expires,
                        name: loginResult.userName,
                        fullName: loginResult.fullName,
                        salutation: loginResult.salutation,
                        phoneNbr: loginResult.phoneNbr,
                        isDach: loginResult.isDach,
                    };
                    this.sessionSubject$.next(session);
                    this.storeSession();

                    return true;
                }

                return false;
            })
        );
    }

    public logout() {
        localStorage.removeItem('session');
        this.sessionSubject$.next(null);
        this.router.navigateByUrl('login');

        return this.loginService.logout().pipe(take(1)).subscribe();
    }

    public getSessionSnapshot(): UserSession {
        return this.sessionSubject$.value;
    }

    private storeSession() {
        if (this.sessionSubject$.value) {
            localStorage.setItem('session', JSON.stringify(this.sessionSubject$.value));
        }
    }

    private restoreSession() {
        const json = localStorage.getItem('session');
        if (json) {
            this.sessionSubject$.next(JSON.parse(json) as UserSession);
        }
    }
}
