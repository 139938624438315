import { Table as DefaultTable } from './default/models';
import { Table as NaueTable } from './naue/models';

export class TableAdapter<T> {
    public convertTableToArray(table: NaueTable | DefaultTable): T[] {
        const array: T[] = [];

        if (!table) {
            return array;
        }

        table.rows.forEach(row => {
            const obj = {};
            row.cells.forEach(c => {
                const cellKey = this.sanitizeCellName(c.name);
                return (obj[cellKey] = c.value);
            });
            array.push(obj as T);
        });

        return array;
    }

    private sanitizeCellName(name: string): string {
        return name.replace(/\./g, '_');
    }
}
