import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {

    private storedRoutes = new Map<string, DetachedRouteHandle>();

    shouldDetach(route: ActivatedRouteSnapshot): boolean {
        return route.data.reuse || false;
    }

    store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        const routeUrl = this.getRouteUrl(route);

        if (route.data.reuse && !!routeUrl) { 
            this.storedRoutes.set(routeUrl, handle);
        }
    }

    shouldAttach(route: ActivatedRouteSnapshot): boolean {
        const routeUrl = this.getRouteUrl(route);

        return !!route.routeConfig && !!this.storedRoutes.get(routeUrl);
    }

    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
        const routeUrl = this.getRouteUrl(route);

        if (!routeUrl) {
            return null;
        }

        return this.storedRoutes.get(routeUrl);
    }

    shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
        return future.routeConfig === curr.routeConfig;
    }

    private getRouteUrl(route: ActivatedRouteSnapshot): string {
        return `${route.parent.url.join('/')}/${route.url.join('/')}`;
    }
}
