import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LanguageService } from '../language.service';

@Component({
    selector: 'app-language-button',
    templateUrl: './language-button.component.html',
    styleUrls: ['./language-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageButtonComponent {
    constructor(private readonly languageService: LanguageService) {}

    language$ = this.languageService.getCurrentLanguage();

    setLanguage(newLang: string) {
        this.languageService.setCurrentLanguage(newLang);
    }
}
