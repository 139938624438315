/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OxApiConfiguration as __Configuration } from '../ox-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponse } from '../models/list-response';

/**
 * Delivery Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class DeliveryEndpointService extends __BaseService {
  static readonly getAllDeliveriesUsingGETPath = '/allDeliveries';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getAllDeliveries
   * @param params The `DeliveryEndpointService.GetAllDeliveriesUsingGETParams` containing the following parameters:
   *
   * - `transaction`: transaction
   *
   * - `toDate`: toDate
   *
   * - `fromDate`: fromDate
   *
   * - `deliveryNumber`: deliveryNumber
   *
   * @return OK
   */
  getAllDeliveriesUsingGETResponse(params: DeliveryEndpointService.GetAllDeliveriesUsingGETParams): __Observable<__StrictHttpResponse<ListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.transaction != null) __params = __params.set('transaction', params.transaction.toString());
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    if (params.deliveryNumber != null) __params = __params.set('deliveryNumber', params.deliveryNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/allDeliveries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponse>;
      })
    );
  }
  /**
   * getAllDeliveries
   * @param params The `DeliveryEndpointService.GetAllDeliveriesUsingGETParams` containing the following parameters:
   *
   * - `transaction`: transaction
   *
   * - `toDate`: toDate
   *
   * - `fromDate`: fromDate
   *
   * - `deliveryNumber`: deliveryNumber
   *
   * @return OK
   */
  getAllDeliveriesUsingGET(params: DeliveryEndpointService.GetAllDeliveriesUsingGETParams): __Observable<ListResponse> {
    return this.getAllDeliveriesUsingGETResponse(params).pipe(
      __map(_r => _r.body as ListResponse)
    );
  }
}

module DeliveryEndpointService {

  /**
   * Parameters for getAllDeliveriesUsingGET
   */
  export interface GetAllDeliveriesUsingGETParams {

    /**
     * transaction
     */
    transaction?: string;

    /**
     * toDate
     */
    toDate?: string;

    /**
     * fromDate
     */
    fromDate?: string;

    /**
     * deliveryNumber
     */
    deliveryNumber?: string;
  }
}

export { DeliveryEndpointService }
