export class DateFormatter {
    public static format(date: Date): string {
        if (!date) {
            return '';
        }

        return `${date.getFullYear()}-` +
            `${(date.getMonth() + 1).toString().padStart(2, '0')}-` +
            `${date.getDate().toString().padStart(2, '0')}`;
    }
}
