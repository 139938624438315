import { Observable } from 'rxjs';
import { PagedResponse } from '../paged-response';
import { OrderModel } from './order-model';
import { OrderPositionModel } from './order-position-model';
import { OrderSearchOptions } from './order-search-options';

export abstract class OrderService {
    public abstract loadList(searchOptions: OrderSearchOptions): Observable<PagedResponse<OrderModel>>;
    public abstract loadSingle(orderNbr: string): Observable<OrderModel>;
    public abstract loadSinglePosition(orderNbr: string, position: number): Observable<OrderPositionModel>;
    public abstract loadOrderNumbersLastYear(): Observable<string[]>;
}
