import { Injectable, NgZone } from '@angular/core';
import { SessionService } from './session.service';

const SECONDS_TILL_LOGOUT = 1800;
const CHECK_INTERVALL = 1000;
const STORE_KEY = 'whenLastAction';

@Injectable({
  providedIn: 'root'
})
export class IdleLogoutService {

  constructor(
    private ngZone: NgZone,
    private sessionService: SessionService
  ) { }

  startListening() {
    this.check();
    // Use ngZone, so no unneccessary change detection is running
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('keypress', () => this.reset());
      setInterval(() => this.check(), CHECK_INTERVALL);
    });
  }

  private get whenLastAction() {
    return parseInt(localStorage.getItem(STORE_KEY) , 10);
  }

  private set whenLastAction(value: number) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  private check() {
    const timeSinceLastAction = Date.now() - this.whenLastAction;

    if (timeSinceLastAction > SECONDS_TILL_LOGOUT * 1000) {
      this.ngZone.run(() => {
        this.sessionService.logout();
        this.reset();
      });
    }
  }

  private reset() {
    this.whenLastAction = Date.now();
  }
}
