import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerInfoModel } from '../shared/customer-infos/customer-info.model';
import { CustomerInfoService } from '../shared/customer-infos/customer-info.service';
import { GetAddressAndBankDataEndpointService } from './naue/services';

@Injectable()
export class OxCustomerInfoService extends CustomerInfoService {
    constructor(private infosService: GetAddressAndBankDataEndpointService) {
        super();
    }

    public getInfos(): Observable<CustomerInfoModel> {
        return this.infosService.getAddressAndBankDataUsingPOST().pipe(map(response => this.mapInfos(response)));
    }

    private mapInfos(resp: { [key: string]: string }): CustomerInfoModel {
        return {
            bankCode: resp.BLZ,
            bankName: resp.BANKNAME,
            city: resp.city,
            company: resp.companyname,
            country: resp.country,
            customerNbr: resp.customernr,
            debitMandate: resp.LMAND,
            iban: resp.IBAN,
            invoiceCity: resp.invoicecity,
            invoiceCompany: resp.invoicecompanyname,
            invoiceStreet1: resp.invoicevstreet1,
            invoiceStreet2: resp.invoicestreet2,
            invoiceZipCode: resp.invoiceplz,
            street1: resp.street1,
            street2: resp.street2,
            zipCode: resp.plz,
        };
    }
}
