<div class="banner"></div>

<div class="row">
    <div class="grid">
        <div class="grid-item welcome">
            <ng-container *ngIf="motd$ | async as motd; else loading">
                <h2>{{motd.headline}}</h2>
                <div class="motd-body">
                    <div *ngFor="let line of motd.body">{{line}}</div>
                </div>
            </ng-container>
        </div>
        <a class="grid-item nav-card bg-image orders-bg" routerLink="orders" (click)="trackClick('Aufträge', '/orders')">
            <button mat-raised-button color="primary" class="nav-card__btn">{{'NAV_ORDERS' | translate}}
            </button>
        </a>
        <a class="grid-item nav-card bg-image articles-bg" routerLink="items" (click)="trackClick('Produkte', '/items')">
            <button mat-raised-button color="primary" class="nav-card__btn">{{'NAV_ARTICLES' | translate}}</button>
        </a>
        <a *ngIf="this.showCart$ | async; else contactItem" class="grid-item nav-card bg-image cart-bg" routerLink="cart" (click)="trackClick('Warenkorb', '/cart')">
            <button mat-raised-button color="primary" class="nav-card__btn">{{'NAV_CART' | translate}}</button>
        </a>

        <ng-template #contactItem>
            <a class="grid-item nav-card bg-image contact-bg" routerLink="contact" (click)="trackClick('Kontakt', '/contact')">
                <button mat-raised-button color="primary" class="nav-card__btn">{{'NAV_CONTACT' | translate}}</button>
            </a>
        </ng-template>
        
    </div>
</div>

<ng-template #loading>
    <app-loading [text]="'HOME_LOADING_WELCOME' | translate"></app-loading>
</ng-template>
