import { Injectable } from '@angular/core';
import { CimLoggingService } from '@cimdata/cim-logging';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, retry, switchMap } from 'rxjs/operators';
import { CartItemModel } from '../shared/cart/cart-item-model';
import { CartModel } from '../shared/cart/cart-model';
import { CartPersistenceService } from '../shared/cart/cart-persistence.service';
import { CartSubmitModel } from '../shared/cart/cart-submit.model';
import { DateFormatter } from './date-formatter';
import { NaueOrder } from './naue/models';
import { DocumentEndpointService, NaueOrderEndpointService } from './naue/services';

@Injectable()
export class OxCartPersistenceService extends CartPersistenceService {
    constructor(
        private orderService: NaueOrderEndpointService,
        private documentService: DocumentEndpointService,
        private logger: CimLoggingService
    ) {
        super();
    }

    public loadCart(): Observable<CartModel> {
        return this.orderService.getArticleCartUsingGET().pipe(
            retry(3),
            map(cart => this.mapCart(cart))
        );
    }

    public addToCart(item: CartItemModel, isDelivery: boolean): Observable<CartModel> {
        const request: NaueOrderEndpointService.AddOrderPositionUsingPOSTParams = {
            delivery: isDelivery,
            deliveryDate: DateFormatter.format(new Date()),
            item: item.productNbr,
            bes2: item.countUnit2,
        };

        return this.orderService.addOrderPositionUsingPOST(request).pipe(
            switchMap(cart => {
                if (!!item.freightCosts) {
                    const lastPos = cart.orderPositions[cart.orderPositions.length - 1];
                    return this.orderService.addDiscountUsingPOST(lastPos.positionNr, item.freightCosts);
                } else {
                    return of(cart);
                }
            }),
            map(cart => this.mapCart(cart))
        );
    }

    public removeItem(item: CartItemModel): Observable<CartModel> {
        return this.orderService.deleteOrderPositionUsingDELETE(item.nbr).pipe(map(cart => this.mapCart(cart)));
    }

    public changeItem(item: CartItemModel, isDelivery: boolean): Observable<CartModel> {
        const params: NaueOrderEndpointService.ChangeOrderPositionUsingPOSTParams = {
            delivery: isDelivery,
            deliveryDate: DateFormatter.format(new Date()),
            bes2: item.countUnit2,
            orderPositionNr: item.nbr,
        };

        return this.orderService.changeOrderPositionUsingPOST(params).pipe(map(cart => this.mapCart(cart)));
    }

    public changeItemDiscount(posNr: number, discount: number): Observable<CartModel> {
        return this.orderService.addDiscountUsingPOST(posNr, discount).pipe(map(cart => this.mapCart(cart)));
    }

    public book(request: CartSubmitModel): Observable<void> {
        const addressParams: NaueOrderEndpointService.StoreDeliveryAddressUsingPOSTParams = {
            countryNumber: request.countryId,
            contact: request.contactPerson,
            plz: request.zipCode,
            street: `${request.street} ${request.streetNbr}`,
            place: request.city,
            phone: request.phone,
            name1: request.constructionCompany,
            name2: request.constructionProject,
        };

        const documentParams = request.files.map(f => {
            const fileParts = f.fileName.split('.');
            const param: DocumentEndpointService.PutOrderDocumentUsingPOSTParams = {
                file: f.b64Content.split(',')[1],
                filename: fileParts[0],
                fileextension: `.${fileParts[1]}`,
                order: request.orderNbr,
            };
            return param;
        });

        const orderParams: NaueOrderEndpointService.ConfirmOrderUsingPOSTParams = {
            order: request.orderNbr,
            costCenter: request.ownCostCenter,
            custOrderNr: request.ownOrder,
            deliveryDate: DateFormatter.format(request.deliveryDate),
            note: request.note,
            constructionCompany: request.constructionCompany,
            constructionProject: request.constructionProject,
        };

        // No need to store delivery address when self collecting
        const firstObs = request.shouldDeliver
            ? this.orderService.storeDeliveryAddressUsingPOST(addressParams)
            : of(null);

        // Do not parallelize the calls, so we don't get problems with locks in the backend
        return firstObs.pipe(
            switchMap(() => {
                if (documentParams.length === 0) {
                    return of<string[]>([]);
                }

                return combineLatest(
                    documentParams.map(d =>
                        this.documentService.putOrderDocumentUsingPOST(d).pipe(
                            catchError(err => {
                                this.logger.error('Error uploading document', err);
                                return of<string>(null);
                            })
                        )
                    )
                );
            }),
            switchMap(() => this.orderService.confirmOrderUsingPOST(orderParams)),
            map(() => {})
        );
    }

    private mapCart(cart: NaueOrder) {
        if (!cart) {
            return null;
        }

        const newCartItems: CartItemModel[] = (cart.orderPositions || []).map(
            (pos): CartItemModel => ({
                countUnit2: pos.quantity2,
                location: pos.prst,
                nbr: pos.positionCounter,
                productGroup: pos.category,
                productName: pos.itemDescription,
                productNbr: pos.item,
                currencyCode: 'EUR',
                orderQuantity: pos.quantity1,
                priceUnit1: pos.quantityUnit1DescriptionShort,
                priceUnit2: pos.quantityUnit2Description,
                singleGross: pos.grossPrice,
                singleNet: pos.grossPrice,
                totalNet: pos.grossPrice * pos.quantity1,
                subProductGroup: pos.subCategory,
                freightCosts: pos.frachtKosten,
                orderPos: pos.positionNr,
            })
        );

        const newCart: CartModel = {
            currency: cart.currency || 'EUR',
            isDelivery: cart.orderHeader?.isDelivery === 'J',
            order: cart.orderNr,
            vatPerc: cart.orderHeader?.mwst || 0,
            items: newCartItems,
            termsOfPayment: this.firstLineOnly(cart.termsOfPayment || ''),
        };

        return newCart;
    }

    private firstLineOnly(str: string): string {
        return str.split('<br>')[0];
    }
}
