import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CimLoggingService } from '@cimdata/cim-logging';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CustomerInfoModel } from '../shared/customer-infos/customer-info.model';
import { CustomerInfoService } from '../shared/customer-infos/customer-info.service';

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit {

  infos$: Observable<CustomerInfoModel>;

  constructor(
    private customerInfoService: CustomerInfoService,
    private logger: CimLoggingService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.infos$ = this.customerInfoService.getInfos().pipe(
      map(infos => ({
        ...infos,
        iban: this.maskIban(infos.iban)
      })),
      catchError(err => {
        this.logger.error(err.message);
        this.snackBar.open('Fehler beim Laden der Informationen aufgetreten.');
        return of<CustomerInfoModel>(null);
      })
    );
  }

  private maskIban(iban: string): string {
    if (!iban?.length) {
     return '';
    }

    return iban.slice(0, -4).split('').map(_ => '*').join('') + iban.slice(-4);
  }
}
