import { SpecialAction, SpecialActionContext } from '../special-action';

export class CartDiscountAction extends SpecialAction {
    shouldShow(ctx: SpecialActionContext): boolean {
        return ctx.userRoles.includes('AKTION_HANDEL');
    }

    getImagePath(): string {
        return './assets/Teaser_3_Prozent_Aktion 2.png';
    }
}
