import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeliveryAddressModel } from '../shared/delivery-addresses/delivery-address.model';
import { DeliveryAddressesService } from '../shared/delivery-addresses/delivery-addresses-service';
import { GetDeliveryAddressesService } from './naue/services';
import { TableAdapter } from './table-adapter';

@Injectable()
export class OxDeliveryAddressesService extends DeliveryAddressesService {
    constructor(private deliveryService: GetDeliveryAddressesService) {
        super();
    }

    public loadList(): Observable<DeliveryAddressModel[]> {
        return this.deliveryService.getDeliveryAddressesUsingPOST().pipe(
            map(resp => new TableAdapter<OxDeliveryAddress>().convertTableToArray(resp.table)),
            map(items => items.map(addr => this.mapAddress(addr)))
        );
    }

    private mapAddress(addr: OxDeliveryAddress): DeliveryAddressModel {
        return {
            ...addr,
            city: addr.place,
            contactPerson: addr.contact,
            zipCode: addr.plz,
            streetNbr: addr.number,
        };
    }
}

interface OxDeliveryAddress {
    AAADR5: string;
    AAADR8: string;
    contact: string;
    countryNumber: string;
    name1: string;
    name2: string;
    name3: string;
    phone: string;
    place: string;
    plz: string;
    street: string;
    number: string;
}
