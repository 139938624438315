/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NaueApiConfiguration, NaueApiConfigurationInterface } from './naue-api-configuration';

import { GetAddressAndBankDataEndpointService } from './services/get-address-and-bank-data-endpoint.service';
import { NaueOrderEndpointService } from './services/naue-order-endpoint.service';
import { ContactMessageEndpointService } from './services/contact-message-endpoint.service';
import { ChangePasswordEndpointService } from './services/change-password-endpoint.service';
import { GetDeliveryAddressesService } from './services/get-delivery-addresses.service';
import { DocumentEndpointService } from './services/document-endpoint.service';
import { GetPersonalAccountsOpenItemsService } from './services/get-personal-accounts-open-items.service';
import { UserService } from './services/user.service';
import { NaueAllOrdersEndpointService } from './services/naue-all-orders-endpoint.service';
import { NaueOpenOrdersEndpointService } from './services/naue-open-orders-endpoint.service';
import { NaueProductCatalogEndpointService } from './services/naue-product-catalog-endpoint.service';
import { NaueConfirmationOfArrivalEndpointService } from './services/naue-confirmation-of-arrival-endpoint.service';
import { NaueGetCountriesService } from './services/naue-get-countries.service';
import { NaueOpenOrderPositionsEndpointService } from './services/naue-open-order-positions-endpoint.service';
import { NauePriceDeterminationEndpointService } from './services/naue-price-determination-endpoint.service';
import { NaueUserInfoEndpointService } from './services/naue-user-info-endpoint.service';
import { NaueRegistrationEndpointService } from './services/naue-registration-endpoint.service';

/**
 * Provider for all NaueApi services, plus NaueApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    NaueApiConfiguration,
    GetAddressAndBankDataEndpointService,
    NaueOrderEndpointService,
    ContactMessageEndpointService,
    ChangePasswordEndpointService,
    GetDeliveryAddressesService,
    DocumentEndpointService,
    GetPersonalAccountsOpenItemsService,
    UserService,
    NaueAllOrdersEndpointService,
    NaueOpenOrdersEndpointService,
    NaueProductCatalogEndpointService,
    NaueConfirmationOfArrivalEndpointService,
    NaueGetCountriesService,
    NaueOpenOrderPositionsEndpointService,
    NauePriceDeterminationEndpointService,
    NaueUserInfoEndpointService,
    NaueRegistrationEndpointService
  ],
})
export class NaueApiModule {
  static forRoot(customParams: NaueApiConfigurationInterface): ModuleWithProviders<NaueApiModule> {
    return {
      ngModule: NaueApiModule,
      providers: [
        {
          provide: NaueApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
