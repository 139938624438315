import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RolesProviderService } from './shared/auth/roles-provider-service';
import { CartService } from './shared/cart/cart-service';
import { CoaService } from './shared/coa/coa.service';
import { IdleLogoutService } from './shared/idle-logout.service';
import { LanguageService } from './shared/language/language.service';
import { OpenPositionService } from './shared/openpos/open-position-service';
import { SessionService } from './shared/session.service';
import { UserSession } from './shared/user-session';

const AGB_DACH_URL = 'assets/VKB Online Innerdeutsch_DE_Juli 2024.pdf';
const AGB_INTERNATIONAL_URL = 'assets/VKB Online Ausland_EN_Juli 2024.pdf';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    isDesktopDevice$: Observable<boolean>;
    session$: Observable<UserSession>;
    countOpenPositions$: Observable<number>;
    countInCart$: Observable<number>;
    countCoa$: Observable<number>;
    canViewOpenPositions$: Observable<boolean>;
    canViewCart$: Observable<boolean>;
    canViewCoa$: Observable<boolean>;
    canViewOrders$: Observable<boolean>;
    canViewItems$: Observable<boolean>;
    agbUrl$: Observable<string>;
    newsUrl$: Observable<string>;

    @ViewChild('sidenav')
    sidenav: MatSidenav;

    curYear = new Date().getFullYear();

    constructor(
        private bpObserver: BreakpointObserver,
        private sessionService: SessionService,
        private openposService: OpenPositionService,
        private cartService: CartService,
        private idleLogoutService: IdleLogoutService,
        private router: Router,
        private rolesProvider: RolesProviderService,
        private languageService: LanguageService,
        private analyticsService: GoogleAnalyticsService,
        private coaService: CoaService
    ) {}

    ngOnInit(): void {
        this.isDesktopDevice$ = this.bpObserver
            .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
            .pipe(map(x => x.matches));

        this.session$ = this.sessionService.session$;

        this.countOpenPositions$ = this.openposService.getCount();
        this.countInCart$ = this.cartService.getCartItemCount();
        this.countCoa$ = this.coaService.getCountOpen();

        this.idleLogoutService.startListening();

        this.canViewOpenPositions$ = this.rolesProvider.canViewOpenPositions();
        this.canViewCart$ = this.rolesProvider.canViewCartAndOrder();
        this.canViewCoa$ = this.rolesProvider.canViewCoa();
        this.canViewOrders$ = this.rolesProvider.canViewOrders();
        this.canViewItems$ = this.rolesProvider.canViewItems();

        this.agbUrl$ = this.sessionService.session$.pipe(
            map(sess => (!sess || sess.isDach ? AGB_DACH_URL : AGB_INTERNATIONAL_URL))
        );

        this.newsUrl$ = this.languageService
            .getCurrentLanguage()
            .pipe(
                map(lang =>
                    lang === 'en'
                        ? 'https://www.naue.com/category/news-en/'
                        : 'https://www.naue.com/de/kategorie/news-de/'
                )
            );

        // Close sidenav on navigation
        this.router.events.pipe(filter(ev => ev instanceof NavigationEnd)).subscribe(() => this.sidenav.close());
    }

    logout() {
        this.sessionService.logout();
    }

    trackAccountMenuClick(action: string): void {
        this.analyticsService.gtag('event', 'account', { action });
    }
}
