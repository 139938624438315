import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleSearchOptions } from './article-search-options';
import { ArticleModel } from './article.model';

@Injectable({
  providedIn: 'root'
})
export abstract class ArticleService {
  public abstract loadList(searchOptions: ArticleSearchOptions): Observable<ArticleModel[]>;
  public abstract loadSingle(nbr: string): Observable<ArticleModel>;
}
