import { registerLocaleData } from '@angular/common';
import { DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';

import { HttpClient } from '@angular/common/http';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';

import localeDe from '@angular/common/locales/de';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';
import { RouteReuseStrategy } from '@angular/router';
import { CimLoggingLevel, CimLoggingModule, ConsoleLogTarget, LocalStorageTarget } from '@cimdata/cim-logging';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
import { DummyModule } from './dummy/dummy.module';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { OxaionModule } from './oxaion/oxaion.module';
import { ProfileComponent } from './profile/profile.component';
import { LanguageButtonComponent } from './shared/language/language-button/language-button.component';
import { TranslatedPaginator } from './shared/translated-paginator';
import { SpecialActionsDialogComponent } from './special-actions/special-actions-dlg.component';

registerLocaleData(localeDe);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/translations/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        ChangePasswordComponent,
        ProfileComponent,
        SpecialActionsDialogComponent,
        LanguageButtonComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            defaultLanguage: 'de',
        }),
        CimLoggingModule.forRoot({
            logLevel: CimLoggingLevel.All,
            targets: [new LocalStorageTarget(), new ConsoleLogTarget()],
        }),

        MatSidenavModule,
        MatFormFieldModule,
        MatCardModule,
        MatMenuModule,

        NgxGoogleAnalyticsModule.forRoot('G-9EDWLJBCVV'),
        NgxGoogleAnalyticsRouterModule,

        DummyModule,
        OxaionModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useFactory: (translate: TranslateService) => translate.currentLang || translate.defaultLang || 'de',
            deps: [TranslateService],
        },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { duration: 5000, panelClass: 'naue-snackbar-panel' } as MatSnackBarConfig,
        },
        { provide: MatPaginatorIntl, useClass: TranslatedPaginator, deps: [TranslateService] },
        { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
        { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
