import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { take } from 'rxjs/operators';
import { LoginService } from '../shared/auth/login-service';
import { SessionService } from '../shared/session.service';

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
    changeForm: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private loginService: LoginService,
        private sessionService: SessionService,
        private matSnackbar: MatSnackBar
    ) {}

    ngOnInit(): void {
        this.changeForm = this.formBuilder.group(
            {
                oldPass: ['', Validators.required],
                newPass: ['', Validators.required],
                confirm: ['', Validators.required],
            },
            {
                validators: [
                    this.passwordsMatch(
                        g => g.get('newPass'),
                        g => g.get('confirm')
                    ),
                ],
            }
        );
    }

    submit(): void {
        if (this.changeForm.invalid) {
            return;
        }

        const { oldPass, newPass } = this.changeForm.value;
        this.loginService
            .changePassword(oldPass, newPass)
            .pipe(take(1))
            .subscribe(
                () => this.sessionService.logout(),
                err => {
                    this.matSnackbar.open(`${err.message}`);
                }
            );
    }

    passwordsMatch(
        password: (grp: AbstractControl) => AbstractControl,
        confirm: (grp: AbstractControl) => AbstractControl
    ): ValidatorFn {
        return grp => {
            const pwControl = password(grp);
            const confirmControl = confirm(grp);

            let errors = confirmControl.errors;
            if (errors && errors.notMatching) {
                delete errors.notMatching;
            }

            if (
                pwControl &&
                confirmControl &&
                pwControl.value &&
                confirmControl.value &&
                pwControl.value !== confirmControl.value
            ) {
                if (!errors) {
                    errors = {};
                }
                errors.notMatching = true;
            }

            confirmControl.setErrors(errors);
            return errors;
        };
    }

    get c() {
        return this.changeForm.controls;
    }
}
