import { Observable } from 'rxjs/internal/Observable';
import { DocumentModel } from './document.model';

export abstract class DocumentService {
    public abstract loadOrderDocuments(orderNbr: string): Observable<DocumentModel[]>;
    public abstract loadItemDocuments(itemNbr: string): Observable<DocumentModel[]>;
    public abstract downloadDocument(docId: string, extension: string, fileName: string): Observable<void>;
    public abstract showCartOffer(orderNbr: string): Observable<void>;

    public downloadDocumentByModel(doc: DocumentModel): Observable<void> {
        return this.downloadDocument(doc.id, doc.typeName, doc.fileName);
    }
}
