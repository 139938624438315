/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OxApiConfiguration as __Configuration } from '../ox-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * User Info Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class UserInfoEndpointService extends __BaseService {
  static readonly getUserAuthorizationsUsingGETPath = '/userAuthorizations';
  static readonly getUserInfoUsingGET1Path = '/userInfo';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getUserAuthorizations
   * @return OK
   */
  getUserAuthorizationsUsingGETResponse(): __Observable<__StrictHttpResponse<{[key: string]: Array<string>}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/userAuthorizations`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: Array<string>}>;
      })
    );
  }
  /**
   * getUserAuthorizations
   * @return OK
   */
  getUserAuthorizationsUsingGET(): __Observable<{[key: string]: Array<string>}> {
    return this.getUserAuthorizationsUsingGETResponse().pipe(
      __map(_r => _r.body as {[key: string]: Array<string>})
    );
  }

  /**
   * getUserInfo
   * @return OK
   */
  getUserInfoUsingGET1Response(): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/userInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * getUserInfo
   * @return OK
   */
  getUserInfoUsingGET1(): __Observable<{[key: string]: string}> {
    return this.getUserInfoUsingGET1Response().pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module UserInfoEndpointService {
}

export { UserInfoEndpointService }
