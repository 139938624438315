import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BehaviorSubject, Observable } from 'rxjs';
import { take } from 'rxjs/operators';

const STORAGE_KEY = 'naue-language';

export abstract class LanguageService {
    private currentLang$ = new BehaviorSubject<string>(
        this.translateService.currentLang || this.translateService.defaultLang || 'de'
    );

    constructor(private translateService: TranslateService, protected analyticsService: GoogleAnalyticsService) {
        const lang = localStorage.getItem(STORAGE_KEY);
        if (lang) {
            this.setCurrentLanguage(lang);
        }
    }

    protected abstract setCurrentLanguageInner(lang: string): Observable<void>;

    public setCurrentLanguage(lang: string) {
        this.translateService.use(lang);
        this.analyticsService.gtag('event', 'toggle_language', { language: lang });
        this.writeCurrentLanguage(lang);
    }

    public writeCurrentLanguage(lang = this.currentLang$.value) {
        this.setCurrentLanguageInner(lang)
            .pipe(take(1))
            .subscribe(() => {
                this.currentLang$.next(lang);
                localStorage.setItem(STORAGE_KEY, lang);
            });
    }

    public getCurrentLanguage(): Observable<string> {
        return this.currentLang$.asObservable();
    }
}
