/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { NaueApiConfiguration as __Configuration } from '../naue-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { NaueOrder } from '../models/naue-order';
import { Address } from '../models/address';
import { NaueOrderHeader } from '../models/naue-order-header';

/**
 * Naue Order Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class NaueOrderEndpointService extends __BaseService {
  static readonly confirmOrderUsingPOSTPath = '/confirmOrder';
  static readonly addDiscountUsingPOSTPath = '/naueAddDiscount';
  static readonly addOrderPositionUsingPOSTPath = '/naueAddOrderPosition';
  static readonly getArticleCartUsingGETPath = '/naueArticleCart';
  static readonly changeOrderPositionUsingPOSTPath = '/naueChangeOrderPosition';
  static readonly deleteOrderPositionUsingDELETEPath = '/naueDeleteOrderPosition';
  static readonly storeBillingAddressUsingPOSTPath = '/naueStoreBillingAddress';
  static readonly storeDeliveryAddressUsingPOSTPath = '/naueStoreDeliveryAddress';
  static readonly storeHeaderUsingPOSTPath = '/naueStoreHeader';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * confirmOrder
   * @param params The `NaueOrderEndpointService.ConfirmOrderUsingPOSTParams` containing the following parameters:
   *
   * - `order`: order
   *
   * - `note`: note
   *
   * - `deliveryDate`: deliveryDate
   *
   * - `custOrderNr`: custOrderNr
   *
   * - `costCenter`: costCenter
   *
   * - `constructionProject`: constructionProject
   *
   * - `constructionCompany`: constructionCompany
   *
   * @return OK
   */
  confirmOrderUsingPOSTResponse(params: NaueOrderEndpointService.ConfirmOrderUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.order != null) __params = __params.set('order', params.order.toString());
    if (params.note != null) __params = __params.set('note', params.note.toString());
    if (params.deliveryDate != null) __params = __params.set('deliveryDate', params.deliveryDate.toString());
    if (params.custOrderNr != null) __params = __params.set('custOrderNr', params.custOrderNr.toString());
    if (params.costCenter != null) __params = __params.set('costCenter', params.costCenter.toString());
    if (params.constructionProject != null) __params = __params.set('constructionProject', params.constructionProject.toString());
    if (params.constructionCompany != null) __params = __params.set('constructionCompany', params.constructionCompany.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/confirmOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * confirmOrder
   * @param params The `NaueOrderEndpointService.ConfirmOrderUsingPOSTParams` containing the following parameters:
   *
   * - `order`: order
   *
   * - `note`: note
   *
   * - `deliveryDate`: deliveryDate
   *
   * - `custOrderNr`: custOrderNr
   *
   * - `costCenter`: costCenter
   *
   * - `constructionProject`: constructionProject
   *
   * - `constructionCompany`: constructionCompany
   *
   * @return OK
   */
  confirmOrderUsingPOST(params: NaueOrderEndpointService.ConfirmOrderUsingPOSTParams): __Observable<string> {
    return this.confirmOrderUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * addDiscount
   * @param positionNr positionNr
   * @param discount discount
   * @return OK
   */
  addDiscountUsingPOSTResponse(positionNr?: number,
    discount?: number): __Observable<__StrictHttpResponse<NaueOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (positionNr != null) __params = __params.set('positionNr', positionNr.toString());
    if (discount != null) __params = __params.set('discount', discount.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/naueAddDiscount`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NaueOrder>;
      })
    );
  }
  /**
   * addDiscount
   * @param positionNr positionNr
   * @param discount discount
   * @return OK
   */
  addDiscountUsingPOST(positionNr?: number,
    discount?: number): __Observable<NaueOrder> {
    return this.addDiscountUsingPOSTResponse(positionNr, discount).pipe(
      __map(_r => _r.body as NaueOrder)
    );
  }

  /**
   * addOrderPosition
   * @param params The `NaueOrderEndpointService.AddOrderPositionUsingPOSTParams` containing the following parameters:
   *
   * - `item`: item
   *
   * - `deliveryDate`: deliveryDate
   *
   * - `delivery`: delivery
   *
   * - `bes2`: bes2
   *
   * @return OK
   */
  addOrderPositionUsingPOSTResponse(params: NaueOrderEndpointService.AddOrderPositionUsingPOSTParams): __Observable<__StrictHttpResponse<NaueOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.item != null) __params = __params.set('item', params.item.toString());
    if (params.deliveryDate != null) __params = __params.set('deliveryDate', params.deliveryDate.toString());
    if (params.delivery != null) __params = __params.set('delivery', params.delivery.toString());
    if (params.bes2 != null) __params = __params.set('bes2', params.bes2.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/naueAddOrderPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NaueOrder>;
      })
    );
  }
  /**
   * addOrderPosition
   * @param params The `NaueOrderEndpointService.AddOrderPositionUsingPOSTParams` containing the following parameters:
   *
   * - `item`: item
   *
   * - `deliveryDate`: deliveryDate
   *
   * - `delivery`: delivery
   *
   * - `bes2`: bes2
   *
   * @return OK
   */
  addOrderPositionUsingPOST(params: NaueOrderEndpointService.AddOrderPositionUsingPOSTParams): __Observable<NaueOrder> {
    return this.addOrderPositionUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as NaueOrder)
    );
  }

  /**
   * getArticleCart
   * @return OK
   */
  getArticleCartUsingGETResponse(): __Observable<__StrictHttpResponse<NaueOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/naueArticleCart`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NaueOrder>;
      })
    );
  }
  /**
   * getArticleCart
   * @return OK
   */
  getArticleCartUsingGET(): __Observable<NaueOrder> {
    return this.getArticleCartUsingGETResponse().pipe(
      __map(_r => _r.body as NaueOrder)
    );
  }

  /**
   * changeOrderPosition
   * @param params The `NaueOrderEndpointService.ChangeOrderPositionUsingPOSTParams` containing the following parameters:
   *
   * - `orderPositionNr`: orderPositionNr
   *
   * - `deliveryDate`: deliveryDate
   *
   * - `delivery`: delivery
   *
   * - `bes2`: bes2
   *
   * @return OK
   */
  changeOrderPositionUsingPOSTResponse(params: NaueOrderEndpointService.ChangeOrderPositionUsingPOSTParams): __Observable<__StrictHttpResponse<NaueOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.orderPositionNr != null) __params = __params.set('orderPositionNr', params.orderPositionNr.toString());
    if (params.deliveryDate != null) __params = __params.set('deliveryDate', params.deliveryDate.toString());
    if (params.delivery != null) __params = __params.set('delivery', params.delivery.toString());
    if (params.bes2 != null) __params = __params.set('bes2', params.bes2.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/naueChangeOrderPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NaueOrder>;
      })
    );
  }
  /**
   * changeOrderPosition
   * @param params The `NaueOrderEndpointService.ChangeOrderPositionUsingPOSTParams` containing the following parameters:
   *
   * - `orderPositionNr`: orderPositionNr
   *
   * - `deliveryDate`: deliveryDate
   *
   * - `delivery`: delivery
   *
   * - `bes2`: bes2
   *
   * @return OK
   */
  changeOrderPositionUsingPOST(params: NaueOrderEndpointService.ChangeOrderPositionUsingPOSTParams): __Observable<NaueOrder> {
    return this.changeOrderPositionUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as NaueOrder)
    );
  }

  /**
   * deleteOrderPosition
   * @param positionNr positionNr
   * @return OK
   */
  deleteOrderPositionUsingDELETEResponse(positionNr?: number): __Observable<__StrictHttpResponse<NaueOrder>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (positionNr != null) __params = __params.set('positionNr', positionNr.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/naueDeleteOrderPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NaueOrder>;
      })
    );
  }
  /**
   * deleteOrderPosition
   * @param positionNr positionNr
   * @return OK
   */
  deleteOrderPositionUsingDELETE(positionNr?: number): __Observable<NaueOrder> {
    return this.deleteOrderPositionUsingDELETEResponse(positionNr).pipe(
      __map(_r => _r.body as NaueOrder)
    );
  }

  /**
   * storeBillingAddress
   * @param params The `NaueOrderEndpointService.StoreBillingAddressUsingPOSTParams` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `phone`: phone
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * - `contact`: contact
   *
   * @return OK
   */
  storeBillingAddressUsingPOSTResponse(params: NaueOrderEndpointService.StoreBillingAddressUsingPOSTParams): __Observable<__StrictHttpResponse<Address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.plz != null) __params = __params.set('plz', params.plz.toString());
    if (params.place != null) __params = __params.set('place', params.place.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.name4 != null) __params = __params.set('name4', params.name4.toString());
    if (params.name3 != null) __params = __params.set('name3', params.name3.toString());
    if (params.name2 != null) __params = __params.set('name2', params.name2.toString());
    if (params.name1 != null) __params = __params.set('name1', params.name1.toString());
    if (params.countryNumber != null) __params = __params.set('countryNumber', params.countryNumber.toString());
    if (params.contact != null) __params = __params.set('contact', params.contact.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/naueStoreBillingAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Address>;
      })
    );
  }
  /**
   * storeBillingAddress
   * @param params The `NaueOrderEndpointService.StoreBillingAddressUsingPOSTParams` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `phone`: phone
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * - `contact`: contact
   *
   * @return OK
   */
  storeBillingAddressUsingPOST(params: NaueOrderEndpointService.StoreBillingAddressUsingPOSTParams): __Observable<Address> {
    return this.storeBillingAddressUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Address)
    );
  }

  /**
   * storeDeliveryAddress
   * @param params The `NaueOrderEndpointService.StoreDeliveryAddressUsingPOSTParams` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `phone`: phone
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * - `contact`: contact
   *
   * @return OK
   */
  storeDeliveryAddressUsingPOSTResponse(params: NaueOrderEndpointService.StoreDeliveryAddressUsingPOSTParams): __Observable<__StrictHttpResponse<Address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.plz != null) __params = __params.set('plz', params.plz.toString());
    if (params.place != null) __params = __params.set('place', params.place.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.name4 != null) __params = __params.set('name4', params.name4.toString());
    if (params.name3 != null) __params = __params.set('name3', params.name3.toString());
    if (params.name2 != null) __params = __params.set('name2', params.name2.toString());
    if (params.name1 != null) __params = __params.set('name1', params.name1.toString());
    if (params.countryNumber != null) __params = __params.set('countryNumber', params.countryNumber.toString());
    if (params.contact != null) __params = __params.set('contact', params.contact.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/naueStoreDeliveryAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Address>;
      })
    );
  }
  /**
   * storeDeliveryAddress
   * @param params The `NaueOrderEndpointService.StoreDeliveryAddressUsingPOSTParams` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `phone`: phone
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * - `contact`: contact
   *
   * @return OK
   */
  storeDeliveryAddressUsingPOST(params: NaueOrderEndpointService.StoreDeliveryAddressUsingPOSTParams): __Observable<Address> {
    return this.storeDeliveryAddressUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Address)
    );
  }

  /**
   * storeHeader
   * @param params The `NaueOrderEndpointService.StoreHeaderUsingPOSTParams` containing the following parameters:
   *
   * - `shippingMethod`: shippingMethod
   *
   * - `mitFracht`: mitFracht
   *
   * - `eMail`: eMail
   *
   * - `deliveryDetails2`: deliveryDetails2
   *
   * - `deliveryDetails1`: deliveryDetails1
   *
   * - `customerOrderNumber`: customerOrderNumber
   *
   * - `customerOrderEntries`: customerOrderEntries
   *
   * - `contactDetails`: contactDetails
   *
   * - `completeOrder`: completeOrder
   *
   * @return OK
   */
  storeHeaderUsingPOSTResponse(params: NaueOrderEndpointService.StoreHeaderUsingPOSTParams): __Observable<__StrictHttpResponse<NaueOrderHeader>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.shippingMethod != null) __params = __params.set('shippingMethod', params.shippingMethod.toString());
    if (params.mitFracht != null) __params = __params.set('mitFracht', params.mitFracht.toString());
    if (params.eMail != null) __params = __params.set('eMail', params.eMail.toString());
    if (params.deliveryDetails2 != null) __params = __params.set('deliveryDetails2', params.deliveryDetails2.toString());
    if (params.deliveryDetails1 != null) __params = __params.set('deliveryDetails1', params.deliveryDetails1.toString());
    if (params.customerOrderNumber != null) __params = __params.set('customerOrderNumber', params.customerOrderNumber.toString());
    if (params.customerOrderEntries != null) __params = __params.set('customerOrderEntries', params.customerOrderEntries.toString());
    if (params.contactDetails != null) __params = __params.set('contactDetails', params.contactDetails.toString());
    if (params.completeOrder != null) __params = __params.set('completeOrder', params.completeOrder.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/naueStoreHeader`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NaueOrderHeader>;
      })
    );
  }
  /**
   * storeHeader
   * @param params The `NaueOrderEndpointService.StoreHeaderUsingPOSTParams` containing the following parameters:
   *
   * - `shippingMethod`: shippingMethod
   *
   * - `mitFracht`: mitFracht
   *
   * - `eMail`: eMail
   *
   * - `deliveryDetails2`: deliveryDetails2
   *
   * - `deliveryDetails1`: deliveryDetails1
   *
   * - `customerOrderNumber`: customerOrderNumber
   *
   * - `customerOrderEntries`: customerOrderEntries
   *
   * - `contactDetails`: contactDetails
   *
   * - `completeOrder`: completeOrder
   *
   * @return OK
   */
  storeHeaderUsingPOST(params: NaueOrderEndpointService.StoreHeaderUsingPOSTParams): __Observable<NaueOrderHeader> {
    return this.storeHeaderUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as NaueOrderHeader)
    );
  }
}

module NaueOrderEndpointService {

  /**
   * Parameters for confirmOrderUsingPOST
   */
  export interface ConfirmOrderUsingPOSTParams {

    /**
     * order
     */
    order: string;

    /**
     * note
     */
    note?: string;

    /**
     * deliveryDate
     */
    deliveryDate?: string;

    /**
     * custOrderNr
     */
    custOrderNr?: string;

    /**
     * costCenter
     */
    costCenter?: string;

    /**
     * constructionProject
     */
    constructionProject?: string;

    /**
     * constructionCompany
     */
    constructionCompany?: string;
  }

  /**
   * Parameters for addOrderPositionUsingPOST
   */
  export interface AddOrderPositionUsingPOSTParams {

    /**
     * item
     */
    item?: string;

    /**
     * deliveryDate
     */
    deliveryDate?: string;

    /**
     * delivery
     */
    delivery?: boolean;

    /**
     * bes2
     */
    bes2?: number;
  }

  /**
   * Parameters for changeOrderPositionUsingPOST
   */
  export interface ChangeOrderPositionUsingPOSTParams {

    /**
     * orderPositionNr
     */
    orderPositionNr?: number;

    /**
     * deliveryDate
     */
    deliveryDate?: string;

    /**
     * delivery
     */
    delivery?: boolean;

    /**
     * bes2
     */
    bes2?: number;
  }

  /**
   * Parameters for storeBillingAddressUsingPOST
   */
  export interface StoreBillingAddressUsingPOSTParams {

    /**
     * street
     */
    street?: string;

    /**
     * plz
     */
    plz?: string;

    /**
     * place
     */
    place?: string;

    /**
     * phone
     */
    phone?: string;

    /**
     * name4
     */
    name4?: string;

    /**
     * name3
     */
    name3?: string;

    /**
     * name2
     */
    name2?: string;

    /**
     * name1
     */
    name1?: string;

    /**
     * countryNumber
     */
    countryNumber?: string;

    /**
     * contact
     */
    contact?: string;
  }

  /**
   * Parameters for storeDeliveryAddressUsingPOST
   */
  export interface StoreDeliveryAddressUsingPOSTParams {

    /**
     * street
     */
    street?: string;

    /**
     * plz
     */
    plz?: string;

    /**
     * place
     */
    place?: string;

    /**
     * phone
     */
    phone?: string;

    /**
     * name4
     */
    name4?: string;

    /**
     * name3
     */
    name3?: string;

    /**
     * name2
     */
    name2?: string;

    /**
     * name1
     */
    name1?: string;

    /**
     * countryNumber
     */
    countryNumber?: string;

    /**
     * contact
     */
    contact?: string;
  }

  /**
   * Parameters for storeHeaderUsingPOST
   */
  export interface StoreHeaderUsingPOSTParams {

    /**
     * shippingMethod
     */
    shippingMethod?: string;

    /**
     * mitFracht
     */
    mitFracht?: boolean;

    /**
     * eMail
     */
    eMail?: string;

    /**
     * deliveryDetails2
     */
    deliveryDetails2?: string;

    /**
     * deliveryDetails1
     */
    deliveryDetails1?: string;

    /**
     * customerOrderNumber
     */
    customerOrderNumber?: string;

    /**
     * customerOrderEntries
     */
    customerOrderEntries?: string;

    /**
     * contactDetails
     */
    contactDetails?: string;

    /**
     * completeOrder
     */
    completeOrder?: boolean;
  }
}

export { NaueOrderEndpointService }
