import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../shared/session.service';

const BLOB_ENDPOINTS = ['/downloadDocument'];
const TEXT_ENDPOINTS = ['/getOfferPDF', '/contactMessage'];

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
    constructor(private authService: SessionService) {}

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const responseType = this.getResponseTypeByUrl(req.url);

        // Set withCredentials to store the session id as cookie
        req = req.clone({ withCredentials: true, responseType });
        return next.handle(req).pipe(
            catchError((err: HttpErrorResponse) => {
                if ((err.status === 401 || err.status === 403) && !req.url.endsWith('/login')) {
                    // Unauthorized -> Login
                    this.authService.logout();
                    return EMPTY;
                } else {
                    // Rethrow unknown error
                    return throwError(err);
                }
            })
        );
    }

    private getResponseTypeByUrl(url: string): 'json' | 'blob' | 'text' {
        if (BLOB_ENDPOINTS.some(x => url.endsWith(x))) {
            return 'blob';
        }

        if (TEXT_ENDPOINTS.some(x => url.endsWith(x))) {
            return 'text';
        }

        return 'json';
    }
}
