/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { NaueApiConfiguration as __Configuration } from '../naue-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Contact Message Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class ContactMessageEndpointService extends __BaseService {
  static readonly sendMessageUsingPOSTPath = '/contactMessage';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * sendMessage
   * @param params The `ContactMessageEndpointService.SendMessageUsingPOSTParams` containing the following parameters:
   *
   * - `subject`: subject
   *
   * - `salutation`: salutation
   *
   * - `phone`: phone
   *
   * - `message`: message
   *
   * - `lastname`: lastname
   *
   * - `firstname`: firstname
   *
   * - `email`: email
   *
   * - `ordernumber`: ordernumber
   *
   * @return OK
   */
  sendMessageUsingPOSTResponse(params: ContactMessageEndpointService.SendMessageUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.subject != null) __params = __params.set('subject', params.subject.toString());
    if (params.salutation != null) __params = __params.set('salutation', params.salutation.toString());
    if (params.phone != null) __params = __params.set('phone', params.phone.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.lastname != null) __params = __params.set('lastname', params.lastname.toString());
    if (params.firstname != null) __params = __params.set('firstname', params.firstname.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.ordernumber != null) __params = __params.set('ordernumber', params.ordernumber.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/contactMessage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * sendMessage
   * @param params The `ContactMessageEndpointService.SendMessageUsingPOSTParams` containing the following parameters:
   *
   * - `subject`: subject
   *
   * - `salutation`: salutation
   *
   * - `phone`: phone
   *
   * - `message`: message
   *
   * - `lastname`: lastname
   *
   * - `firstname`: firstname
   *
   * - `email`: email
   *
   * - `ordernumber`: ordernumber
   *
   * @return OK
   */
  sendMessageUsingPOST(params: ContactMessageEndpointService.SendMessageUsingPOSTParams): __Observable<string> {
    return this.sendMessageUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module ContactMessageEndpointService {

  /**
   * Parameters for sendMessageUsingPOST
   */
  export interface SendMessageUsingPOSTParams {

    /**
     * subject
     */
    subject: string;

    /**
     * salutation
     */
    salutation: string;

    /**
     * phone
     */
    phone: string;

    /**
     * message
     */
    message: string;

    /**
     * lastname
     */
    lastname: string;

    /**
     * firstname
     */
    firstname: string;

    /**
     * email
     */
    email: string;

    /**
     * ordernumber
     */
    ordernumber?: string;
  }
}

export { ContactMessageEndpointService }
