import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RolesProviderService } from '../shared/auth/roles-provider-service';
import { SessionService } from '../shared/session.service';
import { CartDiscountAction } from './cart-discount/cart-discount-action';
import { SpecialAction, SpecialActionContext } from './special-action';
import { SpecialActionsDialogComponent } from './special-actions-dlg.component';

// Caution: Order matters, first to match conditions wins!
const ACTIONS: SpecialAction[] = [new CartDiscountAction()];

@Injectable({
    providedIn: 'root',
})
export class SpecialActionsService {
    constructor(
        private sessionService: SessionService,
        private rolesProviderService: RolesProviderService,
        private matDialog: MatDialog
    ) {}

    showDialogIfNeeded() {
        const sess = this.sessionService.getSessionSnapshot();
        const roles = this.rolesProviderService.getRolesSnapshot();

        if (!sess) {
            return;
        }

        const ctx: SpecialActionContext = { userEmail: sess.name, userRoles: roles };
        for (const action of ACTIONS) {
            if (action.shouldShow(ctx)) {
                this.matDialog.open(SpecialActionsDialogComponent, { data: action.getImagePath(ctx) });
                return;
            }
        }
    }
}
