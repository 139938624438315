import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class TranslatedPaginator extends MatPaginatorIntl {
    constructor(
        private translateService: TranslateService
    ) {
        super();

        this.translateService.onLangChange.subscribe(() => {
            this.translateLabels();
            this.changes.next();
        });

        this.translateLabels();
    }

    getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const param = {
            min: page * pageSize + 1,
            max: Math.min((page + 1) * pageSize, length),
            total: length
        };
        return this.translateService.instant('PAGINATOR_RANGELABEL', param);
    }

    private translateLabels() {
        this.itemsPerPageLabel = this.translateService.instant('PAGINATOR_ITEMSPERPAGELABEL');
        this.firstPageLabel = this.translateService.instant('PAGINATOR_FIRSTPAGELABEL');
        this.lastPageLabel = this.translateService.instant('PAGINATOR_LASTPAGELABEL');
        this.previousPageLabel = this.translateService.instant('PAGINATOR_PREVIOUSPAGELABEL');
        this.nextPageLabel = this.translateService.instant('PAGINATOR_NEXTPAGELABEL');
    }
}
