/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OxApiConfiguration as __Configuration } from '../ox-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Transferable } from '../models/transferable';

/**
 * Shipping Method Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class ShippingMethodEndpointService extends __BaseService {
  static readonly getShippingCostsUsingGETPath = '/getShippingCosts';
  static readonly getShippingMethodUsingGETPath = '/getShippingMethod';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getShippingCosts
   * @param weight weight
   * @param shippingMethod shippingMethod
   * @return OK
   */
  getShippingCostsUsingGETResponse(weight?: string,
    shippingMethod?: string): __Observable<__StrictHttpResponse<Transferable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (weight != null) __params = __params.set('weight', weight.toString());
    if (shippingMethod != null) __params = __params.set('shippingMethod', shippingMethod.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getShippingCosts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transferable>;
      })
    );
  }
  /**
   * getShippingCosts
   * @param weight weight
   * @param shippingMethod shippingMethod
   * @return OK
   */
  getShippingCostsUsingGET(weight?: string,
    shippingMethod?: string): __Observable<Transferable> {
    return this.getShippingCostsUsingGETResponse(weight, shippingMethod).pipe(
      __map(_r => _r.body as Transferable)
    );
  }

  /**
   * getShippingMethod
   * @return OK
   */
  getShippingMethodUsingGETResponse(): __Observable<__StrictHttpResponse<Transferable>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getShippingMethod`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Transferable>;
      })
    );
  }
  /**
   * getShippingMethod
   * @return OK
   */
  getShippingMethodUsingGET(): __Observable<Transferable> {
    return this.getShippingMethodUsingGETResponse().pipe(
      __map(_r => _r.body as Transferable)
    );
  }
}

module ShippingMethodEndpointService {
}

export { ShippingMethodEndpointService }
