import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ArticleService } from '../shared/articles/article.service';
import { LoginService } from '../shared/auth/login-service';
import { RolesProviderService } from '../shared/auth/roles-provider-service';
import { CartPersistenceService } from '../shared/cart/cart-persistence.service';
import { CoaService } from '../shared/coa/coa.service';
import { ContactService } from '../shared/contact/contact-service';
import { CountriesService } from '../shared/countries/countries.service';
import { CustomerInfoService } from '../shared/customer-infos/customer-info.service';
import { DeliveryAddressesService } from '../shared/delivery-addresses/delivery-addresses-service';
import { DocumentService } from '../shared/documents/document.service';
import { LanguageService } from '../shared/language/language.service';
import { OpenPositionService } from '../shared/openpos/open-position-service';
import { OrderService } from '../shared/orders/order-service';
import { ForgotPasswordService } from '../shared/password/forgot-password-service';
import { PriceDeterminationService } from '../shared/price-determination/price-determination.service';
import { OxCartPersistenceService } from './cart-persistence.service';
import { OxCoaService } from './coa.service';
import { OxContactService } from './contact.service';
import { OxCountriesService } from './countries.service';
import { OxCustomerInfoService } from './customer-info.service';
import { OxApiModule } from './default/ox-api.module';
import { OxDeliveryAddressesService } from './delivery-addresses.service';
import { OxDocumentService } from './document.service';
import { OxForgotPasswordService } from './forgot.password.service';
import { OxItemService } from './item.service';
import { OxLanguageService } from './language.service';
import { OxLoginService } from './login.service';
import { NaueApiModule } from './naue/naue-api.module';
import { OxOpenPositionService } from './open-position.service';
import { OxOrderService } from './order.service';
import { OxPriceDeterminationService } from './price-determination.service';
import { OxRolesProviderService } from './roles-provider.service';
import { SessionInterceptor } from './session.interceptor';

const ROOT_URL = environment.apiRoot;

@NgModule({
    imports: [CommonModule, OxApiModule.forRoot({ rootUrl: ROOT_URL }), NaueApiModule.forRoot({ rootUrl: ROOT_URL })],
    declarations: [],
    providers: [
        { provide: LoginService, useClass: OxLoginService },
        { provide: ArticleService, useClass: OxItemService },
        { provide: OrderService, useClass: OxOrderService },
        { provide: ContactService, useClass: OxContactService },
        { provide: OpenPositionService, useClass: OxOpenPositionService },
        { provide: PriceDeterminationService, useClass: OxPriceDeterminationService },
        { provide: DocumentService, useClass: OxDocumentService },
        { provide: LanguageService, useClass: OxLanguageService },
        { provide: CustomerInfoService, useClass: OxCustomerInfoService },
        { provide: CartPersistenceService, useClass: OxCartPersistenceService },
        { provide: RolesProviderService, useClass: OxRolesProviderService },
        { provide: DeliveryAddressesService, useClass: OxDeliveryAddressesService },
        { provide: ForgotPasswordService, useClass: OxForgotPasswordService },
        { provide: CoaService, useClass: OxCoaService },
        { provide: CountriesService, useClass: OxCountriesService },

        { provide: HTTP_INTERCEPTORS, useClass: SessionInterceptor, multi: true },
    ],
})
export class OxaionModule {}
