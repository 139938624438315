<button mat-stroked-button *ngIf="language$ | async as language" [mat-menu-trigger-for]="menu">
    <mat-icon>language</mat-icon>
    {{language}}
</button>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="setLanguage('de')">
        <mat-icon class="fi fi-de"></mat-icon>
        <span>de</span>
    </button>
    <button mat-menu-item (click)="setLanguage('en')">
        <mat-icon class="fi fi-gb"></mat-icon>
        <span>en</span>
    </button>
    <button mat-menu-item (click)="setLanguage('fr')">
        <mat-icon class="fi fi-fr"></mat-icon>
        <span>fr</span>
    </button>
</mat-menu>