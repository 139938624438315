/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OxApiConfiguration as __Configuration } from '../ox-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Order } from '../models/order';
import { Address } from '../models/address';
import { OrderHeader } from '../models/order-header';

/**
 * Order Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class OrderEndpointService extends __BaseService {
  static readonly addOrderPositionUsingPOST1Path = '/addOrderPosition';
  static readonly bookUsingPOSTPath = '/book';
  static readonly cancelOrderUsingPOSTPath = '/cancelOrder';
  static readonly deleteOrderPositionUsingDELETE1Path = '/deleteOrderPosition';
  static readonly getOrderUsingGETPath = '/getOrder';
  static readonly loadBillingAddressUsingGETPath = '/loadBillingAddress';
  static readonly loadDeliveryAddressUsingGETPath = '/loadDeliveryAddress';
  static readonly storeBillingAddressUsingPOST1Path = '/storeBillingAddress';
  static readonly storeDeliveryAddressUsingPOST1Path = '/storeDeliveryAddress';
  static readonly storeHeaderUsingPOST1Path = '/storeHeader';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * addOrderPosition
   * @param params The `OrderEndpointService.AddOrderPositionUsingPOST1Params` containing the following parameters:
   *
   * - `quantity`: quantity
   *
   * - `item`: item
   *
   * - `deliveryDate`: deliveryDate
   *
   * @return OK
   */
  addOrderPositionUsingPOST1Response(params: OrderEndpointService.AddOrderPositionUsingPOST1Params): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.quantity != null) __params = __params.set('quantity', params.quantity.toString());
    if (params.item != null) __params = __params.set('item', params.item.toString());
    if (params.deliveryDate != null) __params = __params.set('deliveryDate', params.deliveryDate.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/addOrderPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * addOrderPosition
   * @param params The `OrderEndpointService.AddOrderPositionUsingPOST1Params` containing the following parameters:
   *
   * - `quantity`: quantity
   *
   * - `item`: item
   *
   * - `deliveryDate`: deliveryDate
   *
   * @return OK
   */
  addOrderPositionUsingPOST1(params: OrderEndpointService.AddOrderPositionUsingPOST1Params): __Observable<Order> {
    return this.addOrderPositionUsingPOST1Response(params).pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * book
   * @return OK
   */
  bookUsingPOSTResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/book`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * book
   * @return OK
   */
  bookUsingPOST(): __Observable<string> {
    return this.bookUsingPOSTResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * cancelOrder
   * @return OK
   */
  cancelOrderUsingPOSTResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/cancelOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * cancelOrder
   * @return OK
   */
  cancelOrderUsingPOST(): __Observable<string> {
    return this.cancelOrderUsingPOSTResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * deleteOrderPosition
   * @param positionNr positionNr
   * @return OK
   */
  deleteOrderPositionUsingDELETE1Response(positionNr?: number): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (positionNr != null) __params = __params.set('positionNr', positionNr.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/deleteOrderPosition`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * deleteOrderPosition
   * @param positionNr positionNr
   * @return OK
   */
  deleteOrderPositionUsingDELETE1(positionNr?: number): __Observable<string> {
    return this.deleteOrderPositionUsingDELETE1Response(positionNr).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * getOrder
   * @return OK
   */
  getOrderUsingGETResponse(): __Observable<__StrictHttpResponse<Order>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/getOrder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Order>;
      })
    );
  }
  /**
   * getOrder
   * @return OK
   */
  getOrderUsingGET(): __Observable<Order> {
    return this.getOrderUsingGETResponse().pipe(
      __map(_r => _r.body as Order)
    );
  }

  /**
   * loadBillingAddress
   * @return OK
   */
  loadBillingAddressUsingGETResponse(): __Observable<__StrictHttpResponse<Address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/loadBillingAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Address>;
      })
    );
  }
  /**
   * loadBillingAddress
   * @return OK
   */
  loadBillingAddressUsingGET(): __Observable<Address> {
    return this.loadBillingAddressUsingGETResponse().pipe(
      __map(_r => _r.body as Address)
    );
  }

  /**
   * loadDeliveryAddress
   * @return OK
   */
  loadDeliveryAddressUsingGETResponse(): __Observable<__StrictHttpResponse<Address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/loadDeliveryAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Address>;
      })
    );
  }
  /**
   * loadDeliveryAddress
   * @return OK
   */
  loadDeliveryAddressUsingGET(): __Observable<Address> {
    return this.loadDeliveryAddressUsingGETResponse().pipe(
      __map(_r => _r.body as Address)
    );
  }

  /**
   * storeBillingAddress
   * @param params The `OrderEndpointService.StoreBillingAddressUsingPOST1Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * @return OK
   */
  storeBillingAddressUsingPOST1Response(params: OrderEndpointService.StoreBillingAddressUsingPOST1Params): __Observable<__StrictHttpResponse<Address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.plz != null) __params = __params.set('plz', params.plz.toString());
    if (params.place != null) __params = __params.set('place', params.place.toString());
    if (params.name4 != null) __params = __params.set('name4', params.name4.toString());
    if (params.name3 != null) __params = __params.set('name3', params.name3.toString());
    if (params.name2 != null) __params = __params.set('name2', params.name2.toString());
    if (params.name1 != null) __params = __params.set('name1', params.name1.toString());
    if (params.countryNumber != null) __params = __params.set('countryNumber', params.countryNumber.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storeBillingAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Address>;
      })
    );
  }
  /**
   * storeBillingAddress
   * @param params The `OrderEndpointService.StoreBillingAddressUsingPOST1Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * @return OK
   */
  storeBillingAddressUsingPOST1(params: OrderEndpointService.StoreBillingAddressUsingPOST1Params): __Observable<Address> {
    return this.storeBillingAddressUsingPOST1Response(params).pipe(
      __map(_r => _r.body as Address)
    );
  }

  /**
   * storeDeliveryAddress
   * @param params The `OrderEndpointService.StoreDeliveryAddressUsingPOST1Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * @return OK
   */
  storeDeliveryAddressUsingPOST1Response(params: OrderEndpointService.StoreDeliveryAddressUsingPOST1Params): __Observable<__StrictHttpResponse<Address>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.street != null) __params = __params.set('street', params.street.toString());
    if (params.plz != null) __params = __params.set('plz', params.plz.toString());
    if (params.place != null) __params = __params.set('place', params.place.toString());
    if (params.name4 != null) __params = __params.set('name4', params.name4.toString());
    if (params.name3 != null) __params = __params.set('name3', params.name3.toString());
    if (params.name2 != null) __params = __params.set('name2', params.name2.toString());
    if (params.name1 != null) __params = __params.set('name1', params.name1.toString());
    if (params.countryNumber != null) __params = __params.set('countryNumber', params.countryNumber.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storeDeliveryAddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Address>;
      })
    );
  }
  /**
   * storeDeliveryAddress
   * @param params The `OrderEndpointService.StoreDeliveryAddressUsingPOST1Params` containing the following parameters:
   *
   * - `street`: street
   *
   * - `plz`: plz
   *
   * - `place`: place
   *
   * - `name4`: name4
   *
   * - `name3`: name3
   *
   * - `name2`: name2
   *
   * - `name1`: name1
   *
   * - `countryNumber`: countryNumber
   *
   * @return OK
   */
  storeDeliveryAddressUsingPOST1(params: OrderEndpointService.StoreDeliveryAddressUsingPOST1Params): __Observable<Address> {
    return this.storeDeliveryAddressUsingPOST1Response(params).pipe(
      __map(_r => _r.body as Address)
    );
  }

  /**
   * storeHeader
   * @param params The `OrderEndpointService.StoreHeaderUsingPOST1Params` containing the following parameters:
   *
   * - `shippingMethod`: shippingMethod
   *
   * - `eMail`: eMail
   *
   * - `deliveryDetails2`: deliveryDetails2
   *
   * - `deliveryDetails1`: deliveryDetails1
   *
   * - `customerOrderNumber`: customerOrderNumber
   *
   * - `customerOrderEntries`: customerOrderEntries
   *
   * - `contactDetails`: contactDetails
   *
   * - `completeOrder`: completeOrder
   *
   * @return OK
   */
  storeHeaderUsingPOST1Response(params: OrderEndpointService.StoreHeaderUsingPOST1Params): __Observable<__StrictHttpResponse<OrderHeader>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.shippingMethod != null) __params = __params.set('shippingMethod', params.shippingMethod.toString());
    if (params.eMail != null) __params = __params.set('eMail', params.eMail.toString());
    if (params.deliveryDetails2 != null) __params = __params.set('deliveryDetails2', params.deliveryDetails2.toString());
    if (params.deliveryDetails1 != null) __params = __params.set('deliveryDetails1', params.deliveryDetails1.toString());
    if (params.customerOrderNumber != null) __params = __params.set('customerOrderNumber', params.customerOrderNumber.toString());
    if (params.customerOrderEntries != null) __params = __params.set('customerOrderEntries', params.customerOrderEntries.toString());
    if (params.contactDetails != null) __params = __params.set('contactDetails', params.contactDetails.toString());
    if (params.completeOrder != null) __params = __params.set('completeOrder', params.completeOrder.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/storeHeader`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<OrderHeader>;
      })
    );
  }
  /**
   * storeHeader
   * @param params The `OrderEndpointService.StoreHeaderUsingPOST1Params` containing the following parameters:
   *
   * - `shippingMethod`: shippingMethod
   *
   * - `eMail`: eMail
   *
   * - `deliveryDetails2`: deliveryDetails2
   *
   * - `deliveryDetails1`: deliveryDetails1
   *
   * - `customerOrderNumber`: customerOrderNumber
   *
   * - `customerOrderEntries`: customerOrderEntries
   *
   * - `contactDetails`: contactDetails
   *
   * - `completeOrder`: completeOrder
   *
   * @return OK
   */
  storeHeaderUsingPOST1(params: OrderEndpointService.StoreHeaderUsingPOST1Params): __Observable<OrderHeader> {
    return this.storeHeaderUsingPOST1Response(params).pipe(
      __map(_r => _r.body as OrderHeader)
    );
  }
}

module OrderEndpointService {

  /**
   * Parameters for addOrderPositionUsingPOST1
   */
  export interface AddOrderPositionUsingPOST1Params {

    /**
     * quantity
     */
    quantity?: number;

    /**
     * item
     */
    item?: string;

    /**
     * deliveryDate
     */
    deliveryDate?: string;
  }

  /**
   * Parameters for storeBillingAddressUsingPOST1
   */
  export interface StoreBillingAddressUsingPOST1Params {

    /**
     * street
     */
    street?: string;

    /**
     * plz
     */
    plz?: string;

    /**
     * place
     */
    place?: string;

    /**
     * name4
     */
    name4?: string;

    /**
     * name3
     */
    name3?: string;

    /**
     * name2
     */
    name2?: string;

    /**
     * name1
     */
    name1?: string;

    /**
     * countryNumber
     */
    countryNumber?: string;
  }

  /**
   * Parameters for storeDeliveryAddressUsingPOST1
   */
  export interface StoreDeliveryAddressUsingPOST1Params {

    /**
     * street
     */
    street?: string;

    /**
     * plz
     */
    plz?: string;

    /**
     * place
     */
    place?: string;

    /**
     * name4
     */
    name4?: string;

    /**
     * name3
     */
    name3?: string;

    /**
     * name2
     */
    name2?: string;

    /**
     * name1
     */
    name1?: string;

    /**
     * countryNumber
     */
    countryNumber?: string;
  }

  /**
   * Parameters for storeHeaderUsingPOST1
   */
  export interface StoreHeaderUsingPOST1Params {

    /**
     * shippingMethod
     */
    shippingMethod?: string;

    /**
     * eMail
     */
    eMail?: string;

    /**
     * deliveryDetails2
     */
    deliveryDetails2?: string;

    /**
     * deliveryDetails1
     */
    deliveryDetails1?: string;

    /**
     * customerOrderNumber
     */
    customerOrderNumber?: string;

    /**
     * customerOrderEntries
     */
    customerOrderEntries?: string;

    /**
     * contactDetails
     */
    contactDetails?: string;

    /**
     * completeOrder
     */
    completeOrder?: boolean;
  }
}

export { OrderEndpointService }
