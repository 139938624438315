/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OxApiConfiguration as __Configuration } from '../ox-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponse } from '../models/list-response';

/**
 * Invoice Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class InvoiceEndpointService extends __BaseService {
  static readonly getAllInvoicesUsingGETPath = '/allInvoices';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getAllInvoices
   * @param params The `InvoiceEndpointService.GetAllInvoicesUsingGETParams` containing the following parameters:
   *
   * - `transaction`: transaction
   *
   * - `toDate`: toDate
   *
   * - `invoiceNumber`: invoiceNumber
   *
   * - `fromDate`: fromDate
   *
   * - `Status`: Status
   *
   * @return OK
   */
  getAllInvoicesUsingGETResponse(params: InvoiceEndpointService.GetAllInvoicesUsingGETParams): __Observable<__StrictHttpResponse<ListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.transaction != null) __params = __params.set('transaction', params.transaction.toString());
    if (params.toDate != null) __params = __params.set('toDate', params.toDate.toString());
    if (params.invoiceNumber != null) __params = __params.set('invoiceNumber', params.invoiceNumber.toString());
    if (params.fromDate != null) __params = __params.set('fromDate', params.fromDate.toString());
    if (params.Status != null) __params = __params.set('Status', params.Status.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/allInvoices`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponse>;
      })
    );
  }
  /**
   * getAllInvoices
   * @param params The `InvoiceEndpointService.GetAllInvoicesUsingGETParams` containing the following parameters:
   *
   * - `transaction`: transaction
   *
   * - `toDate`: toDate
   *
   * - `invoiceNumber`: invoiceNumber
   *
   * - `fromDate`: fromDate
   *
   * - `Status`: Status
   *
   * @return OK
   */
  getAllInvoicesUsingGET(params: InvoiceEndpointService.GetAllInvoicesUsingGETParams): __Observable<ListResponse> {
    return this.getAllInvoicesUsingGETResponse(params).pipe(
      __map(_r => _r.body as ListResponse)
    );
  }
}

module InvoiceEndpointService {

  /**
   * Parameters for getAllInvoicesUsingGET
   */
  export interface GetAllInvoicesUsingGETParams {

    /**
     * transaction
     */
    transaction?: string;

    /**
     * toDate
     */
    toDate?: string;

    /**
     * invoiceNumber
     */
    invoiceNumber?: string;

    /**
     * fromDate
     */
    fromDate?: string;

    /**
     * Status
     */
    Status?: string;
  }
}

export { InvoiceEndpointService }
