import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CimLoggingService } from '@cimdata/cim-logging';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Article } from './article';
import { Coa } from './coa';
import { DummyData } from './dummy-data';
import { MessageOfTheDay } from './motd';
import { OpenPosition } from './open-position';
import { Order } from './order';
import { UserData } from './user-data';

@Injectable({
    providedIn: 'root',
})
export class DummyDataService {
    private cached$: Observable<DummyData>;

    constructor(private httpClient: HttpClient, private logger: CimLoggingService) {}

    public fetchData(forceReload: boolean = false): Observable<DummyData> {
        if (!this.cached$ || forceReload) {
            const basePath = './assets/dummy-data';
            const allData = combineLatest([
                this.fetchDataOrDefault<UserData[]>(`${basePath}/user-data.json`, []),
                this.fetchDataOrDefault<Order[]>(`${basePath}/orders.json`, []),
                this.fetchDataOrDefault<{ [language: string]: MessageOfTheDay }>(`${basePath}/motd.json`, {}),
                this.fetchDataOrDefault<OpenPosition[]>(`${basePath}/openpos.json`, []),
                this.fetchDataOrDefault<Article[]>(`${basePath}/articles.json`, []),
                this.fetchDataOrDefault<Coa[]>(`${basePath}/coa.json`, []),
            ]);

            this.cached$ = allData.pipe(
                map(
                    ([userData, orders, motds, openPositions, articles, coas]): DummyData =>
                        ({
                            userData,
                            orders,
                            motds,
                            openPositions,
                            articles,
                            coas,
                        } as DummyData)
                ),
                publishReplay(1),
                refCount()
            );
        }

        return this.cached$;
    }

    private fetchDataOrDefault<TData>(path: string, defaultVal: TData) {
        const userHash = btoa(`${environment.dummyUser}:${environment.dummyPassword}`);
        const headers = new HttpHeaders({ Authorization: `Basic ${userHash}` });

        return this.httpClient.get<TData>(path, { headers }).pipe(
            catchError(err => {
                this.logger.error('Error on loading dummy data, falling back to default', err);
                return of<TData>(defaultVal);
            })
        );
    }
}
