import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DocumentModel } from '../shared/documents/document.model';
import { DocumentService } from '../shared/documents/document.service';
import { Table } from './default/models';
import { OxDocumentItem } from './document-item.model';
import { DocumentEndpointService } from './naue/services';
import { TableAdapter } from './table-adapter';

@Injectable()
export class OxDocumentService extends DocumentService {
    constructor(private documentService: DocumentEndpointService) {
        super();
    }

    public loadOrderDocuments(orderNbr: string): Observable<DocumentModel[]> {
        return this.documentService.getOrderDocumentsUsingPOST(orderNbr).pipe(
            startWith(null as Table),
            map(table => (table ? new TableAdapter<OxDocumentItem>().convertTableToArray(table) : null)),
            map(docs => docs?.map(d => this.mapDocumentModel(d)))
        );
    }

    public loadItemDocuments(itemNbr: string): Observable<DocumentModel[]> {
        return this.documentService.getProductDocumentsUsingPOST(itemNbr).pipe(
            startWith(null as Table),
            map(table => (table ? new TableAdapter<OxDocumentItem>().convertTableToArray(table) : null)),
            map(docs => docs?.map(d => this.mapDocumentModel(d)).sort((d1, d2) => d1.name.localeCompare(d2.name)))
        );
    }

    public downloadDocument(docId: string, extension: string, fileName: string): Observable<void> {
        const request: DocumentEndpointService.DownloadDocumentUsingPOSTParams = {
            d3id: docId,
            fileextension: extension,
        };

        return this.documentService.downloadDocumentUsingPOST(request).pipe(
            map(blob => {
                // Create a link pointing to the ObjectURL containing the blob.
                const data = window.URL.createObjectURL(blob as MediaSource);

                const link = document.createElement('a');
                link.href = data;
                link.download = fileName;
                link.click();

                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
            })
        );
    }

    public showCartOffer(orderNbr: string): Observable<void> {
        return this.documentService.getOfferPDFUsingPOST(orderNbr).pipe(
            map(resp => {
                const dataUrl = `data:application/pdf;base64,${encodeURI(resp)}`;

                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'offer.pdf';
                link.click();

                setTimeout(() => link.remove(), 100);
            })
        );
    }

    private mapDocumentModel(doc: OxDocumentItem): DocumentModel {
        const infos = doc.infos
            // Cut { } at start and end
            .substring(1, doc.infos.length - 1)
            .split(',')
            .map(x => x.trim().split('='))
            .reduce((res, entry) => res.set(entry[0], entry[1]), new Map<string, string>());

        return {
            id: doc.d3id,
            date: new Date(infos.get('date')),
            fileName: `${infos.get('name')}.${infos.get('fileType')}`,
            name: infos.get('name'),
            typeName: infos.get('fileType'),
        };
    }
}
