import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DummyModule } from '../dummy/dummy.module';
import { ArticleImageNamePipe } from './article-image-name.pipe';
import { ArticleToCartComponent } from './article-to-cart/article-to-cart.component';
import { UomTranslatePipe } from './article-to-cart/uom-translate.pipe';
import { AutoFocusDirective } from './auto-focus.directive';
import { CeilPipe } from './ceil.pipe';
import { CommitableInputComponent } from './commitable-input/commitable-input.component';
import { DateDifferencePipe } from './date-difference.pipe';
import { DocumentTableComponent } from './document-table/document-table.component';
import { LoadingComponent } from './loading/loading.component';
import { MapViewComponent } from './map-view/map-view.component';
import { PadNumberPipe } from './pad-number.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SearchCardComponent } from './search-card/search-card.component';

@NgModule({
    declarations: [
        SearchCardComponent,
        LoadingComponent,
        DateDifferencePipe,
        AutoFocusDirective,
        ArticleImageNamePipe,
        DocumentTableComponent,
        CeilPipe,
        ArticleToCartComponent,
        MapViewComponent,
        SafeHtmlPipe,
        CommitableInputComponent,
        PadNumberPipe,
        UomTranslatePipe,
    ],
    imports: [
        CommonModule,
        DummyModule,
        ReactiveFormsModule,
        MatIconModule,
        MatBadgeModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatTableModule,
        MatInputModule,
        MatDialogModule,
        MatRadioModule,

        TranslateModule.forChild(),
        HttpClientModule,
        RouterModule,
    ],
    exports: [
        SearchCardComponent,
        LoadingComponent,
        ReactiveFormsModule,
        MatIconModule,
        MatButtonModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatButtonToggleModule,
        MatSelectModule,
        MatTableModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatChipsModule,
        DateDifferencePipe,
        AutoFocusDirective,
        ArticleImageNamePipe,
        DocumentTableComponent,
        CeilPipe,
        ArticleToCartComponent,
        SafeHtmlPipe,
        CommitableInputComponent,
        PadNumberPipe,
    ],
    providers: [],
})
export class SharedModule {}
