import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from './auth/login-service';
import { RolesProviderService } from './auth/roles-provider-service';

@Injectable({
    providedIn: 'root',
})
export class RoleBasedAuthGuard implements CanActivate {
    constructor(private rolesProvider: RolesProviderService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
        const neededRolls: string[] = route.data.roles;
        if (!neededRolls || neededRolls.length === 0) {
            return true;
        }

        const observables = neededRolls.map(role => this.rolesProvider.hasRole(role));
        return combineLatest(observables).pipe(
            map(results => {
                const allowed = results.every((val, _) => !!val);

                if (!allowed) {
                    return this.router.createUrlTree(['']);
                }

                return true;
            })
        );
    }
}
