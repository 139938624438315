/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { OxApiConfiguration, OxApiConfigurationInterface } from './ox-api-configuration';

import { OrderEndpointService } from './services/order-endpoint.service';
import { DeliveryEndpointService } from './services/delivery-endpoint.service';
import { InvoiceEndpointService } from './services/invoice-endpoint.service';
import { AllOrdersEndpointService } from './services/all-orders-endpoint.service';
import { ArticleInfoEndpointService } from './services/article-info-endpoint.service';
import { ArticlesEndpointService } from './services/articles-endpoint.service';
import { ProductCatalogEndpointService } from './services/product-catalog-endpoint.service';
import { AvailabilityEndpointService } from './services/availability-endpoint.service';
import { DeliveryTrackingEndpointService } from './services/delivery-tracking-endpoint.service';
import { DocumentStructureService } from './services/document-structure.service';
import { OpenEntitiesEndpointService } from './services/open-entities-endpoint.service';
import { AddressesEndpointService } from './services/addresses-endpoint.service';
import { ShippingMethodEndpointService } from './services/shipping-method-endpoint.service';
import { UserService } from './services/user.service';
import { DeliveryPositionsEndpointService } from './services/delivery-positions-endpoint.service';
import { InvoicePositionsEndpointService } from './services/invoice-positions-endpoint.service';
import { OpenOrderPositionsEndpointService } from './services/open-order-positions-endpoint.service';
import { OpenOrdersEndpointService } from './services/open-orders-endpoint.service';
import { OrderStatusService } from './services/order-status.service';
import { GenericEndpointService } from './services/generic-endpoint.service';
import { PriceDeterminationEndpointService } from './services/price-determination-endpoint.service';
import { LanguageEndpointService } from './services/language-endpoint.service';
import { TestEndpointService } from './services/test-endpoint.service';
import { UserInfoEndpointService } from './services/user-info-endpoint.service';

/**
 * Provider for all OxApi services, plus OxApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    OxApiConfiguration,
    OrderEndpointService,
    DeliveryEndpointService,
    InvoiceEndpointService,
    AllOrdersEndpointService,
    ArticleInfoEndpointService,
    ArticlesEndpointService,
    ProductCatalogEndpointService,
    AvailabilityEndpointService,
    DeliveryTrackingEndpointService,
    DocumentStructureService,
    OpenEntitiesEndpointService,
    AddressesEndpointService,
    ShippingMethodEndpointService,
    UserService,
    DeliveryPositionsEndpointService,
    InvoicePositionsEndpointService,
    OpenOrderPositionsEndpointService,
    OpenOrdersEndpointService,
    OrderStatusService,
    GenericEndpointService,
    PriceDeterminationEndpointService,
    LanguageEndpointService,
    TestEndpointService,
    UserInfoEndpointService
  ],
})
export class OxApiModule {
  static forRoot(customParams: OxApiConfigurationInterface): ModuleWithProviders<OxApiModule> {
    return {
      ngModule: OxApiModule,
      providers: [
        {
          provide: OxApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
