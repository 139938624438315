import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { LanguageService } from '../shared/language/language.service';
import { ForgotPasswordService } from '../shared/password/forgot-password-service';
import { SessionService } from '../shared/session.service';
import { SpecialActionsService } from '../special-actions/special-actions.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
    private loadingSubject$ = new BehaviorSubject<boolean>(false);

    loginForm: UntypedFormGroup;
    loading$ = this.loadingSubject$.asObservable();

    constructor(
        private formBuilder: UntypedFormBuilder,
        private sessionService: SessionService,
        private router: Router,
        private activeRoute: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        private matSnackbar: MatSnackBar,
        private forgotPwService: ForgotPasswordService,
        private translateService: TranslateService,
        private specialActionsService: SpecialActionsService,
        private languageService: LanguageService
    ) {}

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    tryLogin() {
        if (this.loginForm.invalid) {
            return;
        }

        this.loadingSubject$.next(true);

        this.sessionService
            .login(this.loginForm.value.username, this.loginForm.value.password)
            .pipe(
                take(1),
                finalize(() => this.loadingSubject$.next(false))
            )
            .subscribe(loggedIn => {
                if (loggedIn) {
                    const urlParams = this.activeRoute.snapshot.queryParamMap;
                    const returnUrl = urlParams.has('returnUrl') ? urlParams.get('returnUrl') : '';
                    this.checkFirstLogin();
                    this.specialActionsService.showDialogIfNeeded();
                    // Ensure language is set after successfull login so backend is setup correctly
                    this.languageService.writeCurrentLanguage();
                    this.router.navigateByUrl(returnUrl);
                } else {
                    this.loginForm.setErrors({ wrongCredentials: true });
                    // Trigger change detection to show error message
                    this.changeDetectorRef.detectChanges();
                }
            });
    }

    get controls() {
        return this.loginForm.controls;
    }

    private checkFirstLogin() {
        this.forgotPwService
            .isInitialPassword()
            .pipe(take(1))
            .subscribe(isInitial => {
                if (!isInitial) {
                    return;
                }

                const message = this.translateService.instant('LOGIN_INITIALPW_MESSAGE');
                const action = this.translateService.instant('LOGIN_INITIALPW_ACTION');
                const snackbarRef = this.matSnackbar.open(message, action, {
                    duration: 15000,
                    panelClass: 'naue-snackbar-panel',
                });
                snackbarRef.onAction().subscribe(() => this.router.navigate(['/change-pass']));
            });
    }
}
