<div class="row small">
    <mat-card>
        <form [formGroup]="changeForm" (submit)="submit()">
            <mat-card-header>
                <mat-card-title>{{'CHANGE_PASS_TITLE' | translate}}</mat-card-title>
            </mat-card-header>
            <mat-card-content class="align">
                <mat-form-field class="fill">
                    <mat-label>{{'CHANGE_PASS_OLD_PLACEHOLDER' | translate}}</mat-label>
                    <input type="password" formControlName="oldPass" matInput autocomplete="current-password">
                    <mat-error *ngIf="c.oldPass.hasError('required')">{{'CHANGE_PASS_ERROR_OLD_REQUIRED' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field class="fill">
                    <mat-label>{{'CHANGE_PASS_NEW_PLACEHOLDER' | translate}}</mat-label>
                    <input type="password" formControlName="newPass" matInput autocomplete="new-password">
                    <mat-error *ngIf="c.newPass.hasError('required')">{{'CHANGE_PASS_ERROR_NEW_REQUIRED' | translate}}</mat-error>
                </mat-form-field>
                <mat-form-field class="fill">
                    <mat-label>{{'CHANGE_PASS_CONFIRM_PLACEHOLDER' | translate}}</mat-label>
                    <input type="password" formControlName="confirm" matInput autocomplete="new-password">
                    <mat-error *ngIf="c.confirm.hasError('required')">{{'CHANGE_PASS_ERROR_CONFIRM_REQUIRED' | translate}}</mat-error>
                    <mat-error *ngIf="c.confirm.hasError('notMatching')">{{'CHANGE_PASS_ERROR_NOTMATCHING' | translate}}</mat-error>
                </mat-form-field>
            </mat-card-content>
            <mat-card-actions>
                <button class="change-btn" type="submit" mat-raised-button color="primary">{{'CHANGE_PASS_ACTION' | translate}}</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>