import { Injectable } from '@angular/core';
import { combineLatest, merge, Observable } from 'rxjs';
import { DummyDataService } from './data.service';
import { MessageOfTheDayService } from '../shared/motd/motd-service';
import { MessageOfTheDayModel } from '../shared/motd/motd-model';
import { filter, map } from 'rxjs/operators';
import { MessageOfTheDay } from './motd';
import { SessionService } from '../shared/session.service';
import { UserSession } from '../shared/user-session';

@Injectable()
export class DummyMessageOfTheDayService extends MessageOfTheDayService {
  constructor(
    private dataService: DummyDataService,
    private sessionService: SessionService
  ) {
    super();
  }

  public getMessage(language: string): Observable<MessageOfTheDayModel> {
    const data$ = combineLatest([
      this.dataService.fetchData().pipe(map(data => data.motds[language])),
      this.sessionService.session$.pipe(filter(s => !!s))
    ]);

    return data$.pipe(
      map(([motd, session]) => {
        return this.convertMotd(motd, session);
      })
    );
  }

  private convertMotd(motd: MessageOfTheDay, session: UserSession): MessageOfTheDayModel {
    return {
      ...motd,
      headline: motd.headline.replace('{{userName}}', session.fullName)
    };
  }
}
