/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OxApiConfiguration as __Configuration } from '../ox-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * Price Determination Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class PriceDeterminationEndpointService extends __BaseService {
  static readonly getPriceDeterminationUsingGET1Path = '/priceDetermination';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * getPriceDetermination
   * @param quantity quantity
   * @param article article
   * @return OK
   */
  getPriceDeterminationUsingGET1Response(quantity?: string,
    article?: string): __Observable<__StrictHttpResponse<{[key: string]: string}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (quantity != null) __params = __params.set('quantity', quantity.toString());
    if (article != null) __params = __params.set('article', article.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/priceDetermination`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{[key: string]: string}>;
      })
    );
  }
  /**
   * getPriceDetermination
   * @param quantity quantity
   * @param article article
   * @return OK
   */
  getPriceDeterminationUsingGET1(quantity?: string,
    article?: string): __Observable<{[key: string]: string}> {
    return this.getPriceDeterminationUsingGET1Response(quantity, article).pipe(
      __map(_r => _r.body as {[key: string]: string})
    );
  }
}

module PriceDeterminationEndpointService {
}

export { PriceDeterminationEndpointService }
