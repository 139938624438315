import { Observable } from 'rxjs';
import { ItemPriceModel } from './item-price.model';

export abstract class PriceDeterminationService {
    public abstract getPrice(
        itemNbr: string,
        quantity: number,
        withCarriage: boolean,
        unit: string
    ): Observable<ItemPriceModel>;
}
