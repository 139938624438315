/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { NaueApiConfiguration as __Configuration } from '../naue-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';


/**
 * User Aktionen
 */
@Injectable({
  providedIn: 'root',
})
class UserService extends __BaseService {
  static readonly nullUsingPOST2Path = '/login';
  static readonly nullUsingPOST3Path = '/logout';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Loggt den User ein
   * @param username undefined
   * @param password undefined
   */
  nullUsingPOST2Response(username: string,
    password: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (username != null) { __formData.append('username', username as string | Blob);}
    if (password != null) { __formData.append('password', password as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Loggt den User ein
   * @param username undefined
   * @param password undefined
   */
  nullUsingPOST2(username: string,
    password: string): __Observable<null> {
    return this.nullUsingPOST2Response(username, password).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Loggt den User aus
   */
  nullUsingPOST3Response(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Loggt den User aus
   */
  nullUsingPOST3(): __Observable<null> {
    return this.nullUsingPOST3Response().pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UserService {
}

export { UserService }
