import { Injectable } from '@angular/core';
import { CimLoggingService } from '@cimdata/cim-logging';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from '../shared/auth/login-service';
import { ForgotPasswordService } from '../shared/password/forgot-password-service';
import { NewPasswordRequestModel } from '../shared/password/new-password-request.model';
import { ChangePasswordEndpointService } from './naue/services';

@Injectable()
export class OxForgotPasswordService extends ForgotPasswordService {
    constructor(
        private pwService: ChangePasswordEndpointService,
        private loginService: LoginService,
        private logger: CimLoggingService
    ) {
        super();
    }

    public requestToken(email: string): Observable<void> {
        return this.pwService.createNewTokenUsingPOST(email, environment.forgotPwScope).pipe(
            finalize(() => this.loginService.logout()),
            map(() => {})
        );
    }

    public requestNewPassword(request: NewPasswordRequestModel): Observable<void> {
        return this.pwService
            .resetPasswordUsingPOST({ encrypted: false, newPW: request.newPassword, token: request.token })
            .pipe(
                map(resp => {
                    if (resp.responseCode === 'NOTOK') {
                        throw new Error(resp.message);
                    }

                    return;
                }),
                finalize(() => this.loginService.logout())
            );
    }

    public isInitialPassword(): Observable<boolean> {
        return this.pwService.isInitialPasswordUsingPOST().pipe(
            catchError(err => {
                this.logger.error(err.message);
                return of(false);
            })
        );
    }
}
