import { ErrorHandler, Injectable } from '@angular/core';
import { CimLoggingService } from '@cimdata/cim-logging';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
    private logger: CimLoggingService
  ) { }

  handleError(error: any): void {
    this.logger.error('Unhandled error occured', error);
  }
}
