import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleSearchOptions } from '../shared/articles/article-search-options';
import { ArticleModel } from '../shared/articles/article.model';
import { ArticleService } from '../shared/articles/article.service';
import { NaueProductCatalogEndpointService } from './naue/services';
import { TableAdapter } from './table-adapter';

@Injectable()
export class OxItemService extends ArticleService {
    constructor(private catalogService: NaueProductCatalogEndpointService) {
        super();
    }

    public loadList(searchOptions: ArticleSearchOptions): Observable<ArticleModel[]> {
        const request: NaueProductCatalogEndpointService.ListCatalogArticlesUsingGETParams = {
            transaction: '*FIRSTLIST',
            searchString: searchOptions.searchTerm,
            searchFunctions: searchOptions.functions.join(','),
            searchProductgroup: searchOptions.articleGroups.join(','),
        };

        return this.loadCatalogArticles(request);
    }

    public loadSingle(nbr: string): Observable<ArticleModel> {
        const request: NaueProductCatalogEndpointService.ListCatalogArticlesUsingGETParams = {
            transaction: '*FIRSTLIST',
            searchString: nbr,
        };

        return this.loadCatalogArticles(request).pipe(map(art => art.filter(a => a.nbr === nbr)[0]));
    }

    private loadCatalogArticles(
        request: NaueProductCatalogEndpointService.ListCatalogArticlesUsingGETParams
    ): Observable<ArticleModel[]> {
        return this.catalogService.listCatalogArticlesUsingGET(request).pipe(
            map(resp => new TableAdapter<OxArticleItem>().convertTableToArray(resp.table)),
            map(articles => articles.map(a => this.mapArticleModel(a)))
        );
    }

    private mapArticleModel(art: OxArticleItem): ArticleModel {
        const factor = art.KKTL_TLGEW2 ? parseFloat(art.KKTL_TLGEW2.replace(',', '.')) : 1;

        return {
            buyable: art._INTERN_ZZBSJN === 'J',
            descr: art._INTERN_ZZTXZL,
            function: [
                art._INTERN_ZZFKT1,
                art._INTERN_ZZFKT2,
                art._INTERN_ZZFKT3,
                art._INTERN_ZZFKT4,
                art._INTERN_ZZFKT5,
            ].filter(x => !!x)[0],
            location: '',
            name: art.KKTL_TLTLEB,
            nbr: art.USTPSP_SPKKTL,
            productGroup: art._INTERN_ZZPGBZ,
            subProductGroup: art._INTERN_ZZPGB2,
            areaPerRoll: parseFloat(art._INTERN_ZZFLAC.replace(',', '.')),
            unitPrice1: art.KKTL_TLMEK1,
            unitPrice1Descr: art.T_TEXT_TLMEK1_KKTL_T_TEXT_TLMEK1_KKTL_TX_T11001,
            unitPrice1Short: art.T_TEXT_TLMEK1_KKTL_T_TEXT_TLMEK1_KKTL_TX_T11009,
            unitPrice2: art.KKTL_TLMEK2,
            unitPrice2Descr: art.T_TEXT_TLMEK2_KKTL_T_TEXT_TLMEK2_KKTL_TX_T11001,
            unitPrice2Short: art.T_TEXT_TLMEK2_KKTL_T_TEXT_TLMEK2_KKTL_TX_T11009,
            unitPriceFactor: art.TLMDK2_T11103 === 'D' ? factor : 1 / factor,
        };
    }
}

interface OxArticleItem {
    USTPSP_SPKKTL: string;
    USTPSP_SPKSTW: string;
    USTPSP_SPOPTN: string;
    USTPSP_SPPOSN: string;
    KKTL_TLSGE1: string;
    KKTL_TLTLEB: string;
    KKTL_TLSGP2: string;
    _INTERN_OBEZ1: string;
    _INTERN_ZZBSJN: string;
    _INTERN_ZZFKT1: string;
    _INTERN_ZZFKT2: string;
    _INTERN_ZZFKT3: string;
    _INTERN_ZZFKT4: string;
    _INTERN_ZZFKT5: string;
    _INTERN_ZZFKT6: string;
    _INTERN_ZZPGBZ: string;
    _INTERN_ZZFLAC: string;
    _INTERN_ZZPGB2: string;
    _INTERN_ZZTXZL: string;
    // ME1 + Bez
    KKTL_TLMEK1: string;
    T_TEXT_TLMEK1_KKTL_T_TEXT_TLMEK1_KKTL_TX_T11001: string;
    T_TEXT_TLMEK1_KKTL_T_TEXT_TLMEK1_KKTL_TX_T11009: string;
    // ME2 + Bez
    KKTL_TLMEK2: string;
    T_TEXT_TLMEK2_KKTL_T_TEXT_TLMEK2_KKTL_TX_T11001: string;
    T_TEXT_TLMEK2_KKTL_T_TEXT_TLMEK2_KKTL_TX_T11009: string;
    // Faktor
    KKTL_TLGEW2: string;
    // D(ivision) oder M(ultiplikation)
    TLMDK2_T11103: string;
}
