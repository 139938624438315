/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { NaueApiConfiguration as __Configuration } from '../naue-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Table } from '../models/table';
import { NaueGLB } from '../models/naue-glb';

/**
 * Naue Confirmation Of Arrival Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class NaueConfirmationOfArrivalEndpointService extends __BaseService {
  static readonly countOpenGLBUsingGETPath = '/naueCountOpenGLB';
  static readonly countOpenGLBReminderUsingGETPath = '/naueCountOpenGLBReminder';
  static readonly listGLBUsingGETPath = '/naueListGLB';
  static readonly saveGLBUsingPOSTPath = '/naueSaveGLB';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * countOpenGLB
   * @return OK
   */
  countOpenGLBUsingGETResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/naueCountOpenGLB`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * countOpenGLB
   * @return OK
   */
  countOpenGLBUsingGET(): __Observable<number> {
    return this.countOpenGLBUsingGETResponse().pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * countOpenGLBReminder
   * @return OK
   */
  countOpenGLBReminderUsingGETResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/naueCountOpenGLBReminder`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * countOpenGLBReminder
   * @return OK
   */
  countOpenGLBReminderUsingGET(): __Observable<number> {
    return this.countOpenGLBReminderUsingGETResponse().pipe(
      __map(_r => _r.body as number)
    );
  }

  /**
   * listGLB
   * @param isReceived isReceived
   * @return OK
   */
  listGLBUsingGETResponse(isReceived?: boolean): __Observable<__StrictHttpResponse<Table>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (isReceived != null) __params = __params.set('isReceived', isReceived.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/naueListGLB`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Table>;
      })
    );
  }
  /**
   * listGLB
   * @param isReceived isReceived
   * @return OK
   */
  listGLBUsingGET(isReceived?: boolean): __Observable<Table> {
    return this.listGLBUsingGETResponse(isReceived).pipe(
      __map(_r => _r.body as Table)
    );
  }

  /**
   * saveGLB
   * @param glbs glbs
   * @return OK
   */
  saveGLBUsingPOSTResponse(glbs: Array<NaueGLB>): __Observable<__StrictHttpResponse<Table>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = glbs;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/naueSaveGLB`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Table>;
      })
    );
  }
  /**
   * saveGLB
   * @param glbs glbs
   * @return OK
   */
  saveGLBUsingPOST(glbs: Array<NaueGLB>): __Observable<Table> {
    return this.saveGLBUsingPOSTResponse(glbs).pipe(
      __map(_r => _r.body as Table)
    );
  }
}

module NaueConfirmationOfArrivalEndpointService {
}

export { NaueConfirmationOfArrivalEndpointService }
