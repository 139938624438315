/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { NaueApiConfiguration as __Configuration } from '../naue-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponse } from '../models/list-response';

/**
 * Naue Product Catalog Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class NaueProductCatalogEndpointService extends __BaseService {
  static readonly listCatalogArticlesUsingGETPath = '/naueArticlesCatalog';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * listCatalogArticles
   * @param params The `NaueProductCatalogEndpointService.ListCatalogArticlesUsingGETParams` containing the following parameters:
   *
   * - `transaction`: transaction
   *
   * - `searchString`: searchString
   *
   * - `searchProductgroup`: searchProductgroup
   *
   * - `searchFunctions`: searchFunctions
   *
   * @return OK
   */
  listCatalogArticlesUsingGETResponse(params: NaueProductCatalogEndpointService.ListCatalogArticlesUsingGETParams): __Observable<__StrictHttpResponse<ListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.transaction != null) __params = __params.set('transaction', params.transaction.toString());
    if (params.searchString != null) __params = __params.set('searchString', params.searchString.toString());
    if (params.searchProductgroup != null) __params = __params.set('searchProductgroup', params.searchProductgroup.toString());
    if (params.searchFunctions != null) __params = __params.set('searchFunctions', params.searchFunctions.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/naueArticlesCatalog`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponse>;
      })
    );
  }
  /**
   * listCatalogArticles
   * @param params The `NaueProductCatalogEndpointService.ListCatalogArticlesUsingGETParams` containing the following parameters:
   *
   * - `transaction`: transaction
   *
   * - `searchString`: searchString
   *
   * - `searchProductgroup`: searchProductgroup
   *
   * - `searchFunctions`: searchFunctions
   *
   * @return OK
   */
  listCatalogArticlesUsingGET(params: NaueProductCatalogEndpointService.ListCatalogArticlesUsingGETParams): __Observable<ListResponse> {
    return this.listCatalogArticlesUsingGETResponse(params).pipe(
      __map(_r => _r.body as ListResponse)
    );
  }
}

module NaueProductCatalogEndpointService {

  /**
   * Parameters for listCatalogArticlesUsingGET
   */
  export interface ListCatalogArticlesUsingGETParams {

    /**
     * transaction
     */
    transaction?: string;

    /**
     * searchString
     */
    searchString?: string;

    /**
     * searchProductgroup
     */
    searchProductgroup?: string;

    /**
     * searchFunctions
     */
    searchFunctions?: string;
  }
}

export { NaueProductCatalogEndpointService }
