<ng-container *ngIf="infos$ | async as infos; else loading">
    <div class="row">
        <div class="profile-container">
            <div class="card">
                <div class="card__header">
                    <h2>{{'PROFILE_ADDRESS_HEADER' | translate}}</h2>
                </div>
                <div class="card__body">
                    {{'PROFILE_ADDRESS_CUSTOMERNBR_LABEL' | translate}}: {{infos.customerNbr}}<br>
                    <b>{{infos.company}}</b><br>
                    {{infos.street1}} {{infos.street2}}<br>
                    {{infos.zipCode}} {{infos.city}}<br>
                    {{infos.country}}
                </div>
            </div>
            <div class="card" *ngIf="infos.iban || infos.bankName || infos.debitMandate">
                <div class="card__header">
                    <h2>{{'PROFILE_BANKDATA_HEADER' | translate}}</h2>
                </div>
                <div class="card__body">
                    IBAN: {{infos.iban}}<br>
                    {{infos.bankName}}<br>
                    {{infos.debitMandate}}
                </div>
            </div>
            <div class="card">
                <div class="card__header">
                    <h2>{{'PROFILE_INVOICEADDRESS_HEADER' | translate}}</h2>
                </div>
                <div class="card__body">
                    <b>{{infos.invoiceCompany}}</b><br>
                    {{infos.invoiceStreet1}} {{infos.invoiceStreet2}}<br>
                    {{infos.invoiceZipCode}} {{infos.invoiceCity}}<br>
                    {{infos.country}}
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <a mat-raised-button color="primary"
            href="mailto://customercenter@naue.com?subject=Änderung Daten Kundenportal&body=Bitte ändern Sie meine Daten für das Kundenportal">
            {{'PROFILE_REQUEST_CHANGE' | translate}}
        </a>
    </div>
</ng-container>

<ng-template #loading>
    <app-loading [text]="'PROFILE_LOADING_MESSAGE' | translate"></app-loading>
</ng-template>