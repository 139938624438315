/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { NaueApiConfiguration as __Configuration } from '../naue-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Resource } from '../models/resource';
import { Table } from '../models/table';

/**
 * Document Endpoint
 */
@Injectable({
  providedIn: 'root',
})
class DocumentEndpointService extends __BaseService {
  static readonly downloadDocumentUsingPOSTPath = '/downloadDocument';
  static readonly getOfferPDFUsingPOSTPath = '/getOfferPDF';
  static readonly getOrderDocumentsUsingPOSTPath = '/getOrderDocuments';
  static readonly getProductDocumentsUsingPOSTPath = '/getProductDocuments';
  static readonly putOrderDocumentUsingPOSTPath = '/putOrderDocument';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * downloadDocument
   * @param params The `DocumentEndpointService.DownloadDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `fileextension`: fileextension
   *
   * - `d3id`: d3id
   *
   * - `ssid`: ssid
   *
   * @return OK
   */
  downloadDocumentUsingPOSTResponse(params: DocumentEndpointService.DownloadDocumentUsingPOSTParams): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.fileextension != null) __params = __params.set('fileextension', params.fileextension.toString());
    if (params.d3id != null) __params = __params.set('d3id', params.d3id.toString());
    if (params.ssid != null) __params = __params.set('ssid', params.ssid.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/downloadDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * downloadDocument
   * @param params The `DocumentEndpointService.DownloadDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `fileextension`: fileextension
   *
   * - `d3id`: d3id
   *
   * - `ssid`: ssid
   *
   * @return OK
   */
  downloadDocumentUsingPOST(params: DocumentEndpointService.DownloadDocumentUsingPOSTParams): __Observable<Resource> {
    return this.downloadDocumentUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * getOfferPDF
   * @param order order
   * @return OK
   */
  getOfferPDFUsingPOSTResponse(order?: string): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (order != null) __params = __params.set('order', order.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/getOfferPDF`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * getOfferPDF
   * @param order order
   * @return OK
   */
  getOfferPDFUsingPOST(order?: string): __Observable<string> {
    return this.getOfferPDFUsingPOSTResponse(order).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * getOrderDocuments
   * @param order order
   * @param type type
   * @return OK
   */
  getOrderDocumentsUsingPOSTResponse(order: string,
    type?: string): __Observable<__StrictHttpResponse<Table>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (order != null) __params = __params.set('order', order.toString());
    if (type != null) __params = __params.set('type', type.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/getOrderDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Table>;
      })
    );
  }
  /**
   * getOrderDocuments
   * @param order order
   * @param type type
   * @return OK
   */
  getOrderDocumentsUsingPOST(order: string,
    type?: string): __Observable<Table> {
    return this.getOrderDocumentsUsingPOSTResponse(order, type).pipe(
      __map(_r => _r.body as Table)
    );
  }

  /**
   * getProductDocuments
   * @param articlenbr articlenbr
   * @return OK
   */
  getProductDocumentsUsingPOSTResponse(articlenbr: string): __Observable<__StrictHttpResponse<Table>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (articlenbr != null) __params = __params.set('articlenbr', articlenbr.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/getProductDocuments`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Table>;
      })
    );
  }
  /**
   * getProductDocuments
   * @param articlenbr articlenbr
   * @return OK
   */
  getProductDocumentsUsingPOST(articlenbr: string): __Observable<Table> {
    return this.getProductDocumentsUsingPOSTResponse(articlenbr).pipe(
      __map(_r => _r.body as Table)
    );
  }

  /**
   * putOrderDocument
   * @param params The `DocumentEndpointService.PutOrderDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `order`: order
   *
   * - `filename`: filename
   *
   * - `fileextension`: fileextension
   *
   * - `file`: file
   *
   * @return OK
   */
  putOrderDocumentUsingPOSTResponse(params: DocumentEndpointService.PutOrderDocumentUsingPOSTParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.order != null) __params = __params.set('order', params.order.toString());
    if (params.filename != null) __params = __params.set('filename', params.filename.toString());
    if (params.fileextension != null) __params = __params.set('fileextension', params.fileextension.toString());
    __body = params.file;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/putOrderDocument`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * putOrderDocument
   * @param params The `DocumentEndpointService.PutOrderDocumentUsingPOSTParams` containing the following parameters:
   *
   * - `order`: order
   *
   * - `filename`: filename
   *
   * - `fileextension`: fileextension
   *
   * - `file`: file
   *
   * @return OK
   */
  putOrderDocumentUsingPOST(params: DocumentEndpointService.PutOrderDocumentUsingPOSTParams): __Observable<string> {
    return this.putOrderDocumentUsingPOSTResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module DocumentEndpointService {

  /**
   * Parameters for downloadDocumentUsingPOST
   */
  export interface DownloadDocumentUsingPOSTParams {

    /**
     * fileextension
     */
    fileextension: string;

    /**
     * d3id
     */
    d3id: string;

    /**
     * ssid
     */
    ssid?: string;
  }

  /**
   * Parameters for putOrderDocumentUsingPOST
   */
  export interface PutOrderDocumentUsingPOSTParams {

    /**
     * order
     */
    order: string;

    /**
     * filename
     */
    filename: string;

    /**
     * fileextension
     */
    fileextension: string;

    /**
     * file
     */
    file: string;
  }
}

export { DocumentEndpointService }
