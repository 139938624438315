import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ItemPriceModel } from '../shared/price-determination/item-price.model';
import { PriceDeterminationService } from '../shared/price-determination/price-determination.service';
import { NauePriceDeterminationEndpointService } from './naue/services';

@Injectable()
export class OxPriceDeterminationService extends PriceDeterminationService {
    constructor(private priceDeterminationService: NauePriceDeterminationEndpointService) {
        super();
    }

    public getPrice(
        itemNbr: string,
        quantity: number,
        withCarriage: boolean,
        unit: string
    ): Observable<ItemPriceModel> {
        const params: NauePriceDeterminationEndpointService.GetPriceDeterminationUsingGETParams = {
            article: itemNbr,
            quantity: quantity.toString(),
            withCarriage,
            unit,
        };

        return this.priceDeterminationService.getPriceDeterminationUsingGET(params).pipe(
            map(response => this.mapItemPrice(response)),
            startWith(null as ItemPriceModel)
        );
    }

    private mapItemPrice(response: { [key: string]: string }): ItemPriceModel {
        return {
            currencyCode: response.IWESL,
            singleGross: parseFloat(response.OBRPR.replace(',', '.')),
            singleNet: parseFloat(response.ONEPR.replace(',', '.')),
            totalNet: parseFloat(response.ONEPRG.replace(',', '.')),
            orderQuantity: parseFloat(response.IBES1.replace(',', '.')),
            priceUnit1: response.TX_MEK19,
        };
    }
}
