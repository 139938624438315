import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContactService } from '../shared/contact/contact-service';
import { MessageModel } from '../shared/contact/message-model';
import { ContactMessageEndpointService } from './naue/services';

@Injectable()
export class OxContactService extends ContactService {
    constructor(private contactService: ContactMessageEndpointService) {
        super();
    }

    public sendMessage(message: MessageModel): Observable<void> {
        return this.contactService
            .sendMessageUsingPOST({
                ...message,
                lastname: message.lastName,
                firstname: message.firstName,
                ordernumber: message.orderNumber,
            })
            .pipe(map(() => {}));
    }
}
