import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { COA_RIGHT, ITEMS_RIGHT, OPOS_RIGHT, ORDERS_RIGHT, WK_RIGHT } from '../app/oxaion/roles-provider.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { ProfileComponent } from './profile/profile.component';
import { AlreadyAuthenticatedGuardService } from './shared/already-authenticated-guard.service';
import { NotAuthenticatedGuardService } from './shared/not-authenticated-guard.service';
import { RoleBasedAuthGuard } from './shared/role-based-auth.guard';

const routes: Routes = [
    { path: 'home', pathMatch: 'full', redirectTo: '' },
    {
        path: 'orders',
        loadChildren: () => import('./orders/orders.module').then(x => x.OrdersModule),
        canActivate: [NotAuthenticatedGuardService, RoleBasedAuthGuard],
        data: { roles: [ORDERS_RIGHT] },
    },
    {
        path: 'items',
        loadChildren: () => import('./articles/articles.module').then(x => x.ArticlesModule),
        canActivate: [NotAuthenticatedGuardService, RoleBasedAuthGuard],
        data: { roles: [ITEMS_RIGHT] },
    },
    {
        path: 'cart',
        loadChildren: () => import('./cart/cart.module').then(x => x.CartModule),
        canActivate: [NotAuthenticatedGuardService, RoleBasedAuthGuard],
        data: { roles: [WK_RIGHT] },
    },
    {
        path: 'openpos',
        loadChildren: () => import('./openpos/openpos.module').then(x => x.OpenposModule),
        canActivate: [NotAuthenticatedGuardService, RoleBasedAuthGuard],
        data: { roles: [OPOS_RIGHT] },
    },
    {
        path: 'coa',
        loadChildren: () => import('./coa/coa.module').then(m => m.CoaModule),
        canActivate: [NotAuthenticatedGuardService, RoleBasedAuthGuard],
        data: { roles: [COA_RIGHT] },
    },
    {
        path: 'contact',
        loadChildren: () => import('./contact/contact.module').then(x => x.ContactModule),
        canActivate: [NotAuthenticatedGuardService],
    },
    { path: 'profile', component: ProfileComponent, canActivate: [NotAuthenticatedGuardService] },
    { path: 'login', component: LoginComponent, canActivate: [AlreadyAuthenticatedGuardService] },
    { path: 'change-pass', component: ChangePasswordComponent },
    {
        path: 'forgot-pass',
        loadChildren: () =>
            import('./password-forgotten/password-forgotten.module').then(m => m.PasswordForgottenModule),
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [NotAuthenticatedGuardService],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
