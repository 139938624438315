<!-- Main content -->
<mat-sidenav-container>
    <mat-sidenav #sidenav position="start" mode="over">
        <div class="sidebar-menu">
            <a class="navbar-logo" routerLink="">
                <img src="./assets/logo.png">
            </a>
            <ng-container *ngTemplateOutlet="menu" ></ng-container>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <div class="navbar">
            <div class="navbar-row">      
                <a class="navbar-logo" routerLink="">
                    <img src="./assets/logo.png">
                </a>  
                <ng-container *ngIf="isDesktopDevice$ | async; else mobileMenu">
                    <ng-container *ngTemplateOutlet="menu" ></ng-container>
                </ng-container>
        
                <ng-template #mobileMenu>
                    <div class="flex-fill"></div>
                    <button mat-stroked-button class="menu-btn" (click)="sidenav.open()">
                        <mat-icon>menu</mat-icon>
                    </button>
                </ng-template>
            </div>
        </div>

        <a href="https://www.naue.com/privacy-policy" target="_blank" id="cookie-law-info">{{'FOOTER_DATAPROTECTION' | translate}}</a>

        <div class="content-container">
            <router-outlet></router-outlet>
        </div>

        
        <footer id="footer">
          <div class="row">
            Copyright <script>document.write(new Date().getFullYear());</script> Naue GmbH &amp; Co. KG | All Rights Reserved | 
            <a href="https://www.naue.com/de/imprint/" target="_blank"> {{'FOOTER_IMPRESS' | translate}} </a> | 
            <a href="https://www.naue.com/de/datenschutzerklaerung/" target="_blank"> {{'FOOTER_DATAPROTECTION2' | translate}} </a> | 
            <a [href]="agbUrl$ | async" target="_blank"> {{'FOOTER_AGB' | translate}} </a>
          </div>
        </footer>
    </mat-sidenav-content>
</mat-sidenav-container>


<!-- Reusable templates -->
<ng-template #menu>
    <nav class="main-menu" *ngIf="session$ | async">
        <ul class="menu">
            <li class="menu-item" *ngIf="canViewOrders$ | async">
                <a routerLink="orders" routerLinkActive="active">{{'NAV_ORDERS' | translate}}</a>
            </li>
            <li class="menu-item" *ngIf="canViewItems$ | async">
                <a routerLink="items" routerLinkActive="active">{{'NAV_ARTICLES' | translate}}</a>
            </li>
            <li class="menu-item" *ngIf="canViewCart$ | async">
                <a routerLink="cart" routerLinkActive="active">
                    <span [matBadge]="countInCart$ | async" matBadgeOverlap="false">{{'NAV_CART' | translate}}</span>
                </a>
            </li>
            <li class="menu-item" *ngIf="canViewOpenPositions$ | async">
                <a routerLink="openpos" routerLinkActive="active" *ngIf="{count: countOpenPositions$ | async} as context">
                    <span [matBadge]="context.count" [matBadgeHidden]="context.count === 0" matBadgeOverlap="false">{{'NAV_OPENPOS' | translate}}</span>
                </a>
            </li>
            <li class="menu-item" *ngIf="canViewCoa$ | async">
                <a routerLink="coa" routerLinkActive="active">
                    <span [matBadge]="countCoa$ | async" matBadgeOverlap="false">{{'NAV_COA' | translate}}</span>
                </a>
            </li>
            <li class="menu-item">
                <a [href]="newsUrl$ | async" target="_blank"> {{'NAV_NEWS' | translate}} </a>
            </li>
            <li class="menu-item">
                <a routerLink="contact" routerLinkActive="active">{{'NAV_CONTACT' | translate}}</a>
            </li>
        </ul>
    </nav>

    <div class="flex-fill"></div>

    <div class="side-menu">
        <ul class="menu">
            <li class="menu-item">
                <app-language-button></app-language-button>
            </li>
            <ng-container *ngIf="session$ | async as session">
                <li class="menu-item">
                    <button mat-stroked-button [matMenuTriggerFor]="userMenu" aria-label="Show user menu" (click)="trackAccountMenuClick('')">
                        <mat-icon>person</mat-icon>
                        {{session.fullName}}
                    </button>
                </li>
            </ng-container>
        </ul>

        <mat-menu #userMenu>
            <button mat-menu-item routerLink="change-pass" (click)="trackAccountMenuClick('password')">
                <mat-icon>lock</mat-icon>
                <span>{{'NAV_CHANGE_PASSWORD' | translate}}</span>
            </button>
            <a mat-menu-item routerLink="profile" (click)="trackAccountMenuClick('profile')">
                <mat-icon>person</mat-icon>
                <span>{{'NAV_PROFILE' | translate}}</span>
            </a>
            <button mat-menu-item (click)="logout()">
                <mat-icon>exit_to_app</mat-icon>
                <span>{{'NAV_LOGOUT' | translate}}</span>
            </button>
        </mat-menu>
    </div>
</ng-template>