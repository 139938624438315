import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CountriesService } from '../shared/countries/countries.service';
import { CountryModel } from '../shared/countries/country.model';
import { NaueGetCountriesService } from './naue/services';
import { TableAdapter } from './table-adapter';

@Injectable()
export class OxCountriesService extends CountriesService {
    constructor(private countriesService: NaueGetCountriesService) {
        super();
    }

    getCountries(): Observable<CountryModel[]> {
        return this.countriesService.getCountriesUsingPOST().pipe(
            map(resp => new TableAdapter<OxCountry>().convertTableToArray(resp.table)),
            map(items => items.map(c => this.mapCountry(c)).sort((c1, c2) => this.sortCountries(c1, c2)))
        );
    }

    private mapCountry(ctry: OxCountry): CountryModel {
        return { id: ctry.LALANR, name: ctry.LALNAM };
    }

    private sortCountries(c1: CountryModel, c2: CountryModel): number {
        return c1.id.localeCompare(c2.id);
    }
}

class OxCountry {
    LALANR: string;
    LALNAM: string;
}
