import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { RolesProviderService } from '../shared/auth/roles-provider-service';
import { MessageOfTheDayModel } from '../shared/motd/motd-model';
import { MessageOfTheDayService } from '../shared/motd/motd-service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent implements OnInit {
    motd$: Observable<MessageOfTheDayModel>;
    showCart$: Observable<boolean>;

    constructor(
        private translateService: TranslateService,
        private motdService: MessageOfTheDayService,
        private rolesService: RolesProviderService,
        private analyticsService: GoogleAnalyticsService
    ) {}

    ngOnInit(): void {
        this.motd$ = this.translateService.onLangChange.pipe(
            map(lang => lang.lang),
            startWith(this.translateService.currentLang || this.translateService.defaultLang),
            switchMap(lang => this.motdService.getMessage(lang))
        );

        this.showCart$ = this.rolesService.canViewCartAndOrder();
    }

    trackClick(title: string, path: string): void {
        this.analyticsService.gtag('event', 'homepage_click', {
            page_title: title,
            page_location: `${window.location.origin}${path}`,
            page_path: path,
        });
    }
}
