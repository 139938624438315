import { Injectable, OnDestroy } from '@angular/core';
import { CimLoggingService } from '@cimdata/cim-logging';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, retry, startWith, switchMap } from 'rxjs/operators';
import { OrderModel } from '../shared/orders/order-model';
import { OrderPositionModel } from '../shared/orders/order-position-model';
import { OrderSearchOptions } from '../shared/orders/order-search-options';
import { OrderService } from '../shared/orders/order-service';
import { PagedResponse } from '../shared/paged-response';
import { SessionService } from '../shared/session.service';
import { DateFormatter } from './date-formatter';
import { ListResponse } from './naue/models';
import { NaueAllOrdersEndpointService, NaueOpenOrderPositionsEndpointService } from './naue/services';
import { TableAdapter } from './table-adapter';

const PAGE_SIZE = 10;

@Injectable()
export class OxOrderService extends OrderService implements OnDestroy {
    private cachedTotal = 0;
    private cachedEntries: OrderModel[] = [];
    private cachedSearchOptions: OrderSearchOptions;
    private subscription: Subscription;

    constructor(
        private allOrdersService: NaueAllOrdersEndpointService,
        private openPositionsService: NaueOpenOrderPositionsEndpointService,
        private sessionService: SessionService,
        private logger: CimLoggingService
    ) {
        super();

        // Each time a new user logs in, we reload
        this.subscription = this.sessionService.session$
            .pipe(filter(sess => !!sess))
            .subscribe(() => this.resetCache());
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public loadList(searchOptions: OrderSearchOptions): Observable<PagedResponse<OrderModel>> {
        if (
            !this.cachedSearchOptions ||
            this.cachedSearchOptions.dateFrom !== searchOptions.dateFrom ||
            this.cachedSearchOptions.dateTill !== searchOptions.dateTill ||
            this.cachedSearchOptions.searchTerm !== searchOptions.searchTerm ||
            this.cachedSearchOptions.sort !== searchOptions.sort ||
            this.cachedSearchOptions.sortDir !== searchOptions.sortDir ||
            this.cachedSearchOptions.state !== searchOptions.state
        ) {
            this.cachedEntries = [];
            searchOptions.pageIndex = 0;
            this.cachedSearchOptions = searchOptions;
        }

        const cached = this.cachedEntries.slice(
            searchOptions.pageIndex * PAGE_SIZE,
            (searchOptions.pageIndex + 1) * PAGE_SIZE
        );

        if (cached.length > 0) {
            return of<PagedResponse<OrderModel>>({
                data: cached,
                pageIndex: searchOptions.pageIndex,
                pageSize: searchOptions.pageSize,
                total: this.cachedTotal,
            });
        }

        const request: NaueAllOrdersEndpointService.GetAllOrdersUsingGETParams = {
            fromDate: searchOptions.dateFrom ? DateFormatter.format(searchOptions.dateFrom) : null,
            toDate: searchOptions.dateTill ? DateFormatter.format(searchOptions.dateTill) : null,
            searchString: searchOptions.searchTerm,
            srt: searchOptions.sortDir.toUpperCase(),
            srtFld: searchOptions.sort === 'orderNbr' ? 'AUNR' : 'BEDT',
            transaction: searchOptions.pageIndex === 0 ? '*FIRSTLIST' : '*NEXTLIST',
            status: searchOptions.state === 'all' ? '*ALL' : searchOptions.state === 'done' ? '*CLOSED' : '*OPEN',
        };
        return this.allOrdersService.getAllOrdersUsingGET(request).pipe(
            startWith(null as ListResponse),
            switchMap(list => {
                if (!list) {
                    return of(null);
                }

                const orders = new TableAdapter<OxOrderItem>().convertTableToArray(list.table);

                if (!orders.length) {
                    const emptyResp: PagedResponse<OrderModel> = {
                        data: [],
                        pageIndex: 0,
                        pageSize: PAGE_SIZE,
                        total: 0,
                    };

                    return of(emptyResp);
                }

                const ordersWithPositions = orders.map(o =>
                    this.openPositionsService.getOpenOrderPositionsUsingGET(o.VKOPFP_KOAUNR).pipe(
                        retry(3),
                        catchError(err => {
                            this.logger.error(err);
                            return of(null);
                        }),
                        map(posList => {
                            const positions = posList
                                ? new TableAdapter<OxPositionItem>().convertTableToArray(posList.table)
                                : [];
                            return this.mapOrder(o, positions);
                        })
                    )
                );

                return combineLatest(ordersWithPositions).pipe(
                    map(entries => {
                        const totalCount = parseInt(list.info.COUNT, 10);
                        const resp: PagedResponse<OrderModel> = {
                            data: entries,
                            pageIndex: searchOptions.pageIndex,
                            pageSize: PAGE_SIZE,
                            total: totalCount,
                        };

                        this.cachedEntries.push(...entries);
                        this.cachedTotal = totalCount;

                        return resp;
                    })
                );
            })
        );
    }

    public loadOrderNumbersLastYear(): Observable<string[]> {
        return this.allOrdersService.getOrdersLastYearUsingGET().pipe(
            map(resp => new TableAdapter<OxOrderNbrItem>().convertTableToArray(resp.table)),
            map(t => t.map(r => r.KOAUNR))
        );
    }

    public loadSingle(orderNbr: string): Observable<OrderModel> {
        return this.allOrdersService.getAllOrdersUsingGET({ orderNumber: orderNbr, transaction: '*FIRSTLIST' }).pipe(
            map(list => {
                const order = new TableAdapter<OxOrderItem>().convertTableToArray(list.table)[0];
                return this.mapOrder(order, []);
            })
        );
    }

    public loadSinglePosition(orderNbr: string, position: number): Observable<OrderPositionModel> {
        return this.openPositionsService.getOpenOrderPositionsUsingGET(orderNbr).pipe(
            map(list => new TableAdapter<OxPositionItem>().convertTableToArray(list.table)),
            map(
                positions =>
                    positions
                        .filter(p => parseInt(p.VPSDAP_PSPOSI, 10) === position)
                        .map(p => this.mapPosition(orderNbr, p))[0]
            )
        );
    }

    public resetCache() {
        this.cachedEntries = [];
        this.cachedSearchOptions = null;
        this.cachedTotal = 0;
    }

    private mapOrder(order: OxOrderItem, positions: OxPositionItem[]): OrderModel {
        if (!order) {
            return null;
        }

        return {
            constructionProject: order.VKOPFP_KOKBBZ,
            contactMail: order._INTERN_W_ZZMAIL,
            contactPersonName: order.T_TEXT_KOSAKZ_VKOPFP_T_TEXT_KOSAKZ_VKOPFP_TX_KEKNAM,
            contactPhone: order._INTERN_W_ZZTELN,
            date: new Date(order.VKOPFP_KOBEDT).getTime(),
            nbr: order.VKOPFP_KOAUNR,
            state: parseInt(order.VKOPFP_KOVAST, 10),
            stateName: this.getOrderStateName(parseInt(order.VKOPFP_KOVAST, 10)),
            sum: parseFloat(order.VKOPFP_KONEDM.replace(',', '.')),
            positions: positions.map(p => this.mapPosition(order.VKOPFP_KOAUNR, p)),
            customerOrderNbr: order.VKOPFP_KOKBNR,
            isOnline: ['W1', 'W2'].includes(order.VKOPFP_KOBUKZ),
            deliveryConfirmed: parseInt(order.VKOPFP_KOVAST, 10) >= 12,
            currencyCode: order.VKOPFP_KOWESL,
        };
    }

    private mapPosition(orderNbr: string, position: OxPositionItem): OrderPositionModel {
        if (!position) {
            return null;
        }

        return {
            amount: parseFloat(position.VPSDAP_PSBES1?.replace(',', '.') || '0'),
            article: position.UTLST_TLIDNR,
            articleDescr: position.UTLST_TLTLEB,
            constructionProject: position.AUNR_KOAKBBZ,
            currencyCode: position._INTERN_K1WESL,
            isLoaded:
                parseFloat(position.VPSDAP_PSBES1?.replace(',', '.') || '0') > 0 &&
                parseFloat(position.VPSDAP_PSLFS1?.replace(',', '.') || '0') >=
                    parseFloat(position.VPSDAP_PSBES1?.replace(',', '.') || '0'),
            nbr: parseInt(position.VPSDAP_PSPOSI, 10),
            orderNbr,
            prizeUnit: position.T_TEXT_PSMEKZ_VPSDAP_T_TEXT_PSMEKZ_VPSDAP_TX_T11009,
            singlePrice: parseFloat(position.VPSDAP_PSNEPR?.replace(',', '.') || '0'),
            storageUnit: position.T_TEXT_PSMEKZ_VPSDAP_T_TEXT_PSMEKZ_VPSDAP_TX_T11009,
            articleBuyable: position._INTERN_ZZBSJN === 'J',
            deliveryDate: new Date(position.VPSDAP_PSLFDT).getTime(),
        };
    }

    private getOrderStateName(state: number): string {
        switch (state) {
            case 1: // Offen
            default:
                return 'ORDERS_STATE_NEW';

            case 2: // Offen und gedruckt
                return 'ORDERS_STATE_CONFIRMED';

            case 10: // Entnahmeschein erstellt
            case 11: // Entnahmeschein gedruckt
            case 12: // Versandschein gedruckt
            case 13: // Kommissionierauftrag erstellt
            case 14: // Kommissionierauftrag gedruckt
            case 15: // Rückmeldung erfolgt
            case 16: // Teilweise verpackt
            case 17: // Komplett verpackt
            case 18: // Auf Sendung
                return 'ORDERS_STATE_PICKING';

            case 20: // Teilgeliefert
            case 21: // Teilgeliefert & Lieferschein gedruckt
            case 22: // Teilgeliefert & Rechnung gedruckt
                return 'ORDERS_STATE_PARTIALLY_DELIVERED';

            case 24: // Reststorniert
            case 25: // Komplett storniert
            case 28: // Reststorniert und gedruckt
                return 'ORDERS_STATE_CANCELLED';

            case 26: // Vollständig geliefert
            case 27: // Vollständig geliefert & Lieferschein dr.
                return 'ORDERS_STATE_DELIVERED';

            case 29: // Vollständig geliefert und Rechnung gedr.
                return 'ORDERS_STATE_BILLED';

            /* Not used -> fallback to offen
          40: 'Warenkorb',
          43: 'Warenkorb übernommen in Angebot/Auftrag',
          44: 'Warenkorb zurückgestellt',
          50: 'Angebot',
          51: 'Angebot gedruckt',
          58: 'Angebot wurde zu Auftrag',
          59: 'Angebot abgesagt/abgeschlossen',
          60: 'Rahmenauftrag',
          65: 'Rahmen teilweise abgerufen',
          68: 'Rahmen komplett abgerufen',
          69: 'Rahmenauftrag manuell abgeschlossen',
          80: 'Anzahlung',
          89: 'Anzahlungsrechnung erstellt und gedruckt'
      */
        }
    }
}

interface OxOrderItem {
    T_TEXT_KOGBKZ_VKOPFP_T_TEXT_KOGBKZ_VKOPFP_TX_T10301: string;
    T_TEXT_KOGBKZ_VKOPFP_T_TEXT_KOGBKZ_VKOPFP_TX_Z10301: string;
    T_TEXT_KOGBKZ_VKOPFP_T_TEXT_KOGBKZ_VKOPFP_TX_Z10307: string;
    T_TEXT_KOSAKZ_VKOPFP_T_TEXT_KOSAKZ_VKOPFP_TX_KEKNAM: string;
    VKOPFP_KOAFKZ: string;
    VKOPFP_KOAUNR: string;
    VKOPFP_KOBEDT: string;
    VKOPFP_KOGBKZ: string;
    VKOPFP_KOGFAL: string;
    VKOPFP_KOKBBZ: string;
    VKOPFP_KOKBNR: string;
    VKOPFP_KOKZKL: string;
    VKOPFP_KOKZSL: string;
    VKOPFP_KOLAG1: string;
    VKOPFP_KOLFWO: string;
    VKOPFP_KOMARG: string;
    VKOPFP_KONAET: string;
    VKOPFP_KONEDM: string;
    VKOPFP_KOSAKZ: string;
    VKOPFP_KOVAST: string;
    VKOPFP_KOVBSF: string;
    VKOPFP_KOVLSF: string;
    VKOPFP_KOVRT1: string;
    VKOPFP_KOWERK: string;
    VKOPFP_KOBUKZ: string;
    _INTERN_X_OLFDM: string;
    _INTERN_W_ZZTELN: string;
    _INTERN_W_ZZMAIL: string;
    VKOPFP_KOWESL: string;
}

interface OxPositionItem {
    AUNR_KOAGBKZ: string;
    AUNR_KOAKBBZ: string;
    T_TEXT_KOAGBKZ_AUNR_T_TEXT_KOAGBKZ_AUNR_TX_T10301: string;
    T_TEXT_PSLAGO_VPSDAP_T_TEXT_PSLAGO_VPSDAP_TX_LGBEZC: string;
    T_TEXT_PSMEKZ_VPSDAP_T_TEXT_PSMEKZ_VPSDAP_TX_T11009: string;
    UTLST_TLBEZG: string;
    VPSDAP_PSAUNR: string;
    VPSDAP_PSBES1: string;
    VPSDAP_PSEKWS: string;
    VPSDAP_PSGFAL: string;
    VPSDAP_PSIDNR: string;
    VPSDAP_PSLAGO: string;
    VPSDAP_PSLFDM: string;
    VPSDAP_PSLFS1: string;
    VPSDAP_PSLFWO: string;
    VPSDAP_PSMARG: string;
    VPSDAP_PSMEK1: string;
    VPSDAP_PSMEK2: string;
    VPSDAP_PSNEPR: string;
    VPSDAP_PSNEWF: string;
    VPSDAP_PSPDKZ: string;
    VPSDAP_PSPOSI: string;
    VPSDAP_PSPRIV: string;
    VPSDAP_PSWUWO: string;
    VPSDAP_PSLFDT: string;
    _CALC_AMOUNT: string;
    _INTERN_STATW: string;
    _INTERN_X_OOMNG: string;
    _INTERN_ZZBSJN: string;
    UTLST_TLTLEB: string;
    UTLST_TLIDNR: string;
    _INTERN_K1WESL: string;
}

interface OxOrderNbrItem {
    KOAUNR: string;
}
