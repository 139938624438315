import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageOfTheDayService } from '../shared/motd/motd-service';
import { DummyMessageOfTheDayService } from './motd.service';

@NgModule({
    declarations: [],
    imports: [CommonModule],
    providers: [
        // { provide: LoginService, useClass: DummyLoginService },
        // { provide: OrderService, useClass: DummyOrderService },
        { provide: MessageOfTheDayService, useClass: DummyMessageOfTheDayService },
        // { provide: OpenPositionService, useClass: DummyOpenPositionService },
        // { provide: ArticleService, useClass: DummyArticleService },
        // { provide: ContactService, useClass: DummyContactService },
        // { provide: ForgotPasswordService, useClass: DummyForgotPasswordService },
        // { provide: CoaService, useClass: DummyCoaService },
        // { provide: CountriesService, useClass: DummyCountriesService },
    ],
})
export class DummyModule {}
