import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Observable, of } from 'rxjs';
import { LanguageService } from '../shared/language/language.service';
import { SessionService } from '../shared/session.service';
import { LanguageEndpointService } from './default/services';

@Injectable()
export class OxLanguageService extends LanguageService {
    constructor(
        translateService: TranslateService,
        private languageChangeService: LanguageEndpointService,
        private sessionService: SessionService,
        protected analyticsService: GoogleAnalyticsService
    ) {
        super(translateService, analyticsService);
    }

    protected setCurrentLanguageInner(lang: string): Observable<void> {
        if (!this.sessionService?.getSessionSnapshot()) {
            return of(null);
        }

        return this.languageChangeService.setLanguageUsingGET(lang);
    }
}
