import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoginResult } from '../shared/auth/login-result';
import { LoginService } from '../shared/auth/login-service';
import { ChangePasswordEndpointService, NaueUserInfoEndpointService, UserService } from './naue/services';

@Injectable()
export class OxLoginService extends LoginService {
    constructor(
        private authService: UserService,
        private userInfoService: NaueUserInfoEndpointService,
        private changePassService: ChangePasswordEndpointService
    ) {
        super();
    }

    public login(username: string, password: string): Observable<LoginResult | null> {
        return this.authService.nullUsingPOST2(username, password).pipe(
            switchMap(() =>
                this.userInfoService.getUserInfoUsingGET().pipe(map(x => this.getUserInfoFromResponse(username, x)))
            ),
            catchError(() => of(null))
        );
    }

    public logout(): Observable<void> {
        return this.authService.nullUsingPOST3();
    }

    public changePassword(oldPass: string, newPass: string): Observable<void> {
        return this.changePassService
            .setNewPasswordUsingPOST({ encrypted: false, newPW: newPass, oldPW: oldPass })
            .pipe(
                map(resp => {
                    if (resp.responseCode === 'NOTOK') {
                        throw new Error(resp.message);
                    }

                    return;
                })
            );
    }

    private getUserInfoFromResponse(userName: string, data: { [key: string]: string }): LoginResult {
        return {
            expires: 0,
            fullName: [data.customerName1, data.customerName2].filter(x => !!x).join(' ') || '-Kein Name-',
            userName,
            salutation: data.salutation,
            phoneNbr: data.directPhone || data.phone,
            isDach: data.isDACH === 'true',
        };
    }
}
