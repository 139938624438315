<div class="login-bg">
    <div class="row small ">
        <form [formGroup]="loginForm" (submit)="tryLogin()">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{'LOGIN_TITLE' | translate}}</mat-card-title>
                    <mat-card-subtitle>{{'LOGIN_SUBTITLE' | translate}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content class="align">

                    <mat-form-field class="fill">
                        <mat-icon matPrefix
                            [color]="controls.username.touched && controls.username.invalid ? 'error' : 'primary'">
                            person</mat-icon>
                        <input matInput type="text" autocomplete="username"
                            [placeholder]="'LOGIN_USERNAME_PLACEHOLDER' | translate" formControlName="username"
                            required appAutoFocus>
                        <mat-error *ngIf="controls.username.hasError('required')">{{'LOGIN_ERROR_USERNAME_REQUIRED' |
                            translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field class="fill">
                        <mat-icon matPrefix
                            [color]="controls.password.touched && controls.password.invalid ? 'error' : 'primary'">lock
                        </mat-icon>
                        <input matInput type="password" autocomplete="current-password"
                            [placeholder]="'LOGIN_PASSWORD_PLACEHOLDER' | translate" formControlName="password"
                            required>
                        <mat-error *ngIf="controls.password.hasError('required')">
                            {{'LOGIN_ERROR_PASSWORD_REQUIRED' | translate}}</mat-error>
                    </mat-form-field>

                    <mat-error class="text-center" *ngIf="loginForm.hasError('wrongCredentials')">
                        {{'LOGIN_ERROR_WRONG_CREDENTIALS' | translate}}</mat-error>

                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" class="login-btn" [disabled]="loading$ | async">
                        {{'LOGIN_LOGIN_ACTION' | translate}}
                    </button>
                </mat-card-actions>
                <mat-card-actions>
                    <div class="further-actions">
                        <a routerLink="/forgot-pass" [queryParams]="{'mail': controls.username.value}">{{'LOGIN_FORGOT_PASSWORD_ACTION'
                            | translate}}</a>
                        |
                        <a [href]="'mailto: customercenter@naue.com?subject=' + ('LOGIN_REGISTER_SUBJECT' | translate)">
                            {{'LOGIN_REGISTER_ACTION' | translate}}
                        </a>
                    </div>
                </mat-card-actions>
            </mat-card>
        </form>
    </div>
</div>