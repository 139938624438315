import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    templateUrl: './special-actions-dlg.component.html',
    styleUrls: ['./special-actions-dlg.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpecialActionsDialogComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public imgUrl: string) {}
}
